<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#3F033B"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showsnackbar" color="#3F033B" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12 py-4>
        <v-card
          tile
          elevation="0"
          class="rounded-lg"
          :color="$route.name == 'Dashboard' ? 'transparent' : '#ffffff'"
          :flat="$route.name == 'Dashboard' ? true : false"
        >
          <v-layout wrap pa-4>
            <v-flex xs12 sm12 pr-2>
              <v-layout wrap>
                <v-flex xs12 sm9 md10 class="mainHeader2 pb-2">Add Item Details</v-flex>
                <!-- <v-flex xs12 sm3 md2 pb-2 text-right class="subhed2" v-if="taxType=='false'">Type:
                  <v-btn small  @click="changebillgst" v-if="billType=='DEMO'" outlined color="#3F033B">DEMO</v-btn>
                  <v-btn small  @click="changebillnongst" v-if="billType=='GST'" outlined color="#3F033B">GST</v-btn>
                  </v-flex> -->
              </v-layout>
            </v-flex>
            <v-flex xs12 sm3 md2 pr-2 pb-2>
              <v-layout wrap>
                <v-flex xs12 class="tablefont" pr-2> <span>Item</span></v-flex>
                <v-flex xs12 pr-2>
                  <!-- <v-autocomplete
                    :items="ItemList"
                    v-model="purchaseItem.ItemCode"
                    placeholder="Select Item"
                    item-text="name"
                    item-value="_id"
                    outlined
                    @input="getDesign()"
                    color="#b234a9"
                    hide-details
                    dense
                  ></v-autocomplete
                > -->
                  <v-autocomplete
                  solo flat class="txtfield2"
                    @input="getDesign()"
                    color="#b234a9"
                    hide-details
                    dense
                    v-model="purchaseItem.ItemCode"
                    :items="ItemList"
                    label="Select an Item"
                    item-value="_id"
                    item-text="name"
                    :filter="customFilter"
                  >
                    <template slot="selection" slot-scope="data">
                      <v-card elevation="0" :selected="data.selected" class="chip--select" style="font-size:14px;">
                        {{ data.item.name }} - {{ data.item.code }}
                      </v-card>
                    </template>
                    <template slot="item" slot-scope="data">
                      <!-- <v-list-tile-content> -->
                        <v-list
                          v-html="data.item.name + ' - ' + data.item.code"
                        ></v-list>
                      <!-- </v-list-tile-content> -->
                    </template>
                  </v-autocomplete>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm3 md3 pr-2 pb-2>
              <v-layout wrap>
                <v-flex xs12 pr-2 class="tablefont"> <span>Design</span></v-flex>
                <v-flex xs12 pr-2 >
                  <!-- <v-autocomplete
                    :items="designList"
                    v-model="purchaseItem.design"
                    placeholder="Select supplier"
                    item-text="_id.design.name"
                    item-value="_id.design._id"
                    solo flat class="txtfield2"
                    @input="getSupplier(), checktype(purchaseItem.design)"
                    color="#b234a9"
                    hide-details
                    dense
                  ></v-autocomplete> -->
                 
                  <v-autocomplete
                    color="#b234a9"
                    :items="designList"
                    v-model="purchaseItem.design"
                    return-object
                    placeholder="Select Design"
                    item-text="_id.design.name"
                    item-value="_id.id"
                    solo flat class="txtfield2"
                    @input="getSupplier(), checktype(purchaseItem.design._id)"
                    hide-details
                    dense
                  >
                  <template slot="selection" slot-scope="data">
                      <v-card elevation="0" style="font-size:16px;" :selected="data.selected" class="chip--select hide-details">
                        {{ data.item._id.design.name }} - {{ data.item._id.itemPerWeight }}gm
                      </v-card>
                    </template>
                    <template slot="item" slot-scope="data">
                        <v-list
                          v-html="data.item._id.design.name + ' - ' + data.item._id.itemPerWeight +'gm'"
                        ></v-list>
                    </template>
                </v-autocomplete>
        
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm3 md2 pr-2 pb-2>
              <v-layout wrap>
                <v-flex xs12 class="tablefont" pr-2>
                  <span>Supplier</span></v-flex
                >
                <v-flex xs12 pr-2 >
                  <v-autocomplete
                    :items="SupplierList"
                    v-model="purchaseItem.supplierCode"
                    placeholder="Select supplier"
                    item-text="supplierName"
                    item-value="_id"
                    solo flat class="txtfield2"
                    @input="checkQty(), getPurchase()"
                    color="#b234a9"
                    hide-details
                    dense
                  ></v-autocomplete
                ></v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm3 md1 pr-2 v-if="dtype == true"  pb-2>
              <!--  -->
              <v-layout wrap>
                <v-flex xs12 class="tablefont" pr-2>
                  <span>Qty</span></v-flex
                >
                <v-flex xs12 pr-2 >
                  <v-text-field
                    v-model="purchaseItem.quantity"
                    solo flat class="txtfield2"
                     hide-details="auto"
                    @input="cal3()"
                    type="number"
                    :hint="stockCount"
                    :rules="[maxValueRule2(stockcount1),(val) => val >= 0 || 'Value must be greater than or equal to 0']"
                    color="#b234a9"
                    placeholder="Quantity "
                    dense
                  ></v-text-field
                ></v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm3 md1 pr-2 pb-2>
              <v-layout wrap>
                <v-flex xs12 class="tablefont" pr-2> <span>VA (%) </span></v-flex>
                <v-flex xs12 pr-2 >
                  <v-text-field
                    v-model="purchaseItem.va"
                    solo flat class="txtfield2"
                    type="number"
                    :rules="[maxValueRule]"
                    @input="cal1()"
                    color="#b234a9"
                    placeholder="VA "
                    dense
                    hide-details="auto"
                  ></v-text-field
                ></v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm3 md2 pr-2 pb-2>
              <v-layout wrap>
                <v-flex xs12 class="tablefont" pr-2>
                  <span>Boarding Rate(₹)</span></v-flex
                >
                <v-flex xs12 pr-2 >
                  <v-text-field
                    @input="cal1()"
                    type="number"
                    :disabled="itempresent == true ? true : false"
                    v-model="purchaseItem.boardingRate"
                    solo flat class="txtfield2"
                    color="#b234a9"
                    placeholder="Boarding Rate "
                    dense
                    hide-details
                  ></v-text-field
                ></v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm3 md2 pr-2 pb-2>
              <v-layout wrap>
                <v-flex xs12 class="tablefont" pr-2>
                  <span>Net Wt.(gm)</span></v-flex
                >
                <v-flex xs12 pr-2 v-if="dtype == true">
                   <v-text-field
                    disabled
                    type="number"
                    v-model="purchaseItem.netweight"
                    solo flat class="txtfield2"
                    color="#b234a9"
                    placeholder="Weight"
                    dense
                    @input="cal1()"
                    hide-details="auto"
                  ></v-text-field
                >
                  <!-- <v-text-field
                    v-model="purchaseItem.netweight"
                    solo flat class="txtfield2"
                    disabled
                    type="number"
                    @input="cal1()"
                    color="#b234a9"
                    placeholder="Weight"
                    dense
                    hide-details
                  ></v-text-field
                > -->
              </v-flex>
                <v-flex xs12 pr-2 v-else>
                  <v-text-field
                    v-model="purchaseItem.netweight"
                    solo flat class="txtfield2"
                    :hint="totNetWeight"
                    type="number"
                    :rules="[maxValueRule3(totNetWeight1),(val) => val >= 0 || 'Value must be greater than 0']"
                    @input="cal1()"
                    color="#b234a9"
                    placeholder="Weight"
                    dense  hide-details="auto"
                  ></v-text-field
                ></v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm3 md2 pr-2 pb-2>
              <v-layout wrap>
                <v-flex xs12 class="tablefont" pr-2>
                  <span>Making Charge(₹)</span></v-flex
                >
                <v-flex xs12 pr-2 >
                  <v-text-field
                    v-model="purchaseItem.makingCharge"
                    solo flat class="txtfield2"
                    type="number"
                    color="#b234a9"
                    placeholder="Making Charge"
                    dense
                    disabled
                    hide-details
                  ></v-text-field
                ></v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm3 md2 pr-2 pb-2>
              <v-layout wrap>
                <v-flex xs12 class="tablefont" pr-2>
                  <span>Discount(₹)</span></v-flex
                >
                <v-flex xs12 pr-2 >
                  <v-text-field
                    v-model="purchaseItem.discount"
                    solo flat class="txtfield2"
                    type="number"
                    color="#b234a9"
                    placeholder="Discount"
                    dense
                    @input="cal2()"
                    hide-details
                  ></v-text-field
                ></v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm3 md2 pr-2 pb-2>
              <v-layout wrap>
                <v-flex xs12 class="tablefont" pr-2>
                  <span>Total(₹)</span></v-flex
                >
                <v-flex xs12 pr-2 >
                  <v-text-field
                    v-model="purchaseItem.itemTotalAmount"
                    solo flat class="txtfield2"
                    disabled
                    color="#b234a9"
                    placeholder="Total"
                    dense
                    hide-details
                  ></v-text-field
                ></v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs6 sm1 pr-2 align-self-end>
              <v-layout wrap>
                <v-flex xs6 pr-1 pb-0 pb-sm-2>
                  <v-btn @click="validate()" color="#3F053C" dark small
                    ><v-icon>mdi-check</v-icon></v-btn
                  >
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>

          <!---------------------Table-------------------------->
          <v-layout px-4 py-4 wrap>
            <!-- <v-flex xs12 class="subhed2">Quotation {{ quotation }}</v-flex> -->
            <v-flex xs12 v-if="List">
              <v-card elevation="0" v-if="List.length > 0">
                <v-layout wrap>
                  <v-flex xs12>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left tablefont"><b>S.No.</b></th>
                            <th class="text-left tablefont">
                              <b>Item</b>
                            </th>
                            <th class="text-left tablefont"><b>HSN</b></th>
                            <th class="text-left tablefont">
                              <b>Qty</b>
                            </th>
                            <th class="text-left">
                              <span class="tablefont"><b>Gr.wt</b></span
                              ><span class="subheeed">(gm)</span>
                            </th>
                            <th class="text-left">
                              <span class="tablefont"><b>St.wt</b></span
                              ><span class="subheeed">(gm)</span>
                            </th>
                            <th class="text-left">
                              <span class="tablefont"><b>Net.wt</b></span
                              ><span class="subheeed">(gm)</span>
                            </th>
                            <!-- <th class="text-left">
                              <span class="tablefont"><b>St.pr</b></span
                              ><span class="subheeed">(₹)</span>
                            </th> -->
                            <th class="text-left">
                              <span class="tablefont"><b>Mk.Chg</b></span
                              ><span class="subheeed">(₹)</span>
                            </th>
                            <th class="text-left">
                              <span class="tablefont"><b>VA</b></span
                              ><span class="subheeed">(%)</span>
                            </th>
                            <th class="text-left">
                              <span class="tablefont"><b>Discount</b></span
                              ><span class="subheeed">(₹)</span>
                            </th>
                            <!-- <th class="text-left">
                              <span class="tablefont"><b>Ad.MK.Chg</b></span
                              ><span class="subheeed">(₹)</span>
                            </th>
                            <th class="text-left">
                              <span class="tablefont"><b>Ad.VA</b></span
                              ><span class="subheeed">(%)</span>
                            </th> -->
                            <th class="text-left" v-if="billType=='GST' || taxType=='true'">
                              <span class="tablefont"><b>CGST</b></span
                              ><span class="subheeed">(%)</span>
                            </th> <th class="text-left" v-if="billType=='GST' || taxType=='true'">
                              <span class="tablefont"><b>SGST</b></span
                              ><span class="subheeed">(%)</span>
                            </th>
                            <th class="text-left">
                              <span class="tablefont"><b>Total</b></span
                              ><span class="subheeed">(₹)</span>
                            </th>
                            <th class="text-left">
                              <span class="tablefont"><b>Action</b></span
                              ><span class="subheeed"></span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(value, i) in List" :key="i" class="table">
                            <!-- <td>{{ i + 1 }}</td> -->
                            <td>
                              <span>
                                {{ i + 1 }}
                              </span>
                            </td>
                            <td>
                              <span v-if="value.designId"
                                >{{ value.designId.name }}-{{
                                  value.itemId.name
                                }}</span
                              >
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.designId.HSN">
                                {{ value.designId.HSN.hsnCode }}</span
                              >
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.quantity">{{
                                value.quantity
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.grossWeight">{{
                                value.grossWeight
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <td>
                              <span v-if="value.stoneWeight">{{
                                value.stoneWeight
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <td>
                              <span v-if="value.netWeight">{{
                                value.netWeight
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <!-- <td>
                              <span v-if="value.stonePrice">{{
                                value.stonePrice
                              }}</span>
                              <span v-else>0</span>
                            </td> -->
                            <td>
                              <span v-if="value.discountAmount">{{ (value.adjustedMakingCharge).toFixed(2) }}</span>
                              <span v-else>{{
                                value.makingCharge.toFixed(2)
                              }}</span>
                            </td>
                            <td>
                              <span v-if="value.discountAmount">{{ (value.adjustedVA).toFixed(2) }}</span>
                              <span v-else>{{
                                value.valueAddition.toFixed(2)
                              }}</span>
                            </td>
                            <td>
                              <span v-if="value.discountAmount">{{
                                value.discountAmount.toFixed(2)
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <!-- <td>
                              <span v-if="value.adjustedMakingCharge">{{
                                value.adjustedMakingCharge.toFixed(2)
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <td>
                              <span v-if="value.adjustedVA">{{
                                value.adjustedVA.toFixed(2)
                              }}</span>
                              <span v-else>0</span>
                            </td> -->
                            <td v-if="billType=='GST' || taxType=='true'" >
                              <span v-if="value.cgst">{{
                                value.cgst.toFixed(2)
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <td v-if="billType=='GST' || taxType=='true'" >
                              <span v-if="value.sgst">{{
                                value.sgst.toFixed(2)
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <td v-if="billType=='GST' || taxType=='true'" >
                              <span v-if="value.amountWithGst">{{
                                value.amountWithGst.toFixed(2)
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <td v-else>
                              <span v-if="value.amountWithoutGst">{{
                                value.amountWithoutGst.toFixed(2)
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <td>
                              <v-icon
                                size="18"
                                class="pr-2"
                                color="#3F053C"
                                style="cursor: pointer"
                                @click="
                                  (curitem = value),
                                    (tstcat = value.designId),(tsttype=value.designId.countOrSplit)
                                    (editdialogue = true),
                                     getItem()
                                "
                                >mdi-pencil</v-icon
                              >
                              <v-icon
                                size="18"
                                style="cursor: pointer"
                                @click="(dialoge = true), (curId = value._id)"
                                >mdi-delete-outline</v-icon
                              >
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout wrap>
            <v-flex xs12 class="subhed2">
              <!-- <v-layout wrap justify-end py-2>
                <v-flex xs2 text-right pr-4 align-self-center> Tax : </v-flex>
                <v-flex xs2 pr-5>
                  <v-text-field
                    v-model="tax"
                    outlined
                    type="number" 
                    color="#b234a9"
                    dense
                    hide-details
                  ></v-text-field
                ></v-flex>
              </v-layout> -->
              <v-layout justify-end v-if="List" wrap>
                <v-flex xs12 sm6 md2 px-1 v-if="List.length>0">
                  <v-layout wrap py-2>
                    <v-flex
                      xs12
                      text-left
                      pr-4 class="subhed2"
                      align-self-center
                      v-if="saledata.totalNetWeight"
                    >
                      Sale Weight(gm)
                    </v-flex>
                    <v-flex xs12 pr-5 v-if="saledata.totalNetWeight">
                      <v-card outlined class="pa-2">
                        <!-- color="#b234a9" -->
                        {{ saledata.totalNetWeight.toFixed(2) }}</v-card
                      ></v-flex 
                    >
                  </v-layout>
                </v-flex>
                <v-flex xs12 sm6 md2 px-1 v-if="List.length>0">
                  <v-layout wrap py-2 pb-4 v-if="billType=='GST' || taxType=='true'">
                    <v-flex xs12 text-left pr-4 align-self-center class="subhed2" v-if="saledata.totalAmountWithGst">
                      Sale Amount(₹)
                    </v-flex>
                    <v-flex xs12 pr-5 v-if="saledata.totalAmountWithGst">
                      <v-card outlined class="pa-2">
                        {{ saledata.totalAmountWithGst.toFixed(2) }}</v-card
                      ></v-flex
                    >
                  </v-layout>
                  <v-layout wrap py-2 pb-4 v-else>
                    <v-flex xs12 text-left pr-4 align-self-center v-if="saledata.totalAmountWithoutGst">
                      Sale Amount(₹)
                    </v-flex>
                    <v-flex xs12 pr-5 v-if="saledata.totalAmountWithoutGst">
                      <v-card outlined class="pa-2">
                        {{ saledata.totalAmountWithoutGst.toFixed(2) }}</v-card
                      ></v-flex
                    >
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog width="400px" v-model="dialoge">
      <v-card width="400px" class="pa-2">

        <v-layout wrap justify-center>
                <v-flex xs11 text-center><span style="color:#3F053C;" class="menuhead1">Delete</span></v-flex>
                <v-flex xs1 text-right>
                  <v-icon
                  @click="dialoge = false"
                    color="#3F053C"
                    >mdi-close-box</v-icon
                  ></v-flex
                >
                <v-flex xs8 text-center py-4 >
              <v-divider></v-divider>
            </v-flex>
              </v-layout>
        <v-card-text class="pa-4 tablefont"
          >Are you sure you want to remove this item?
        </v-card-text>
        <v-card-actions class="pt-3">
          <v-spacer></v-spacer>
          <!-- <v-btn
            color="grey"
            text
            class="body-2 font-weight-bold"
            @click="dialoge = false"
            >Cancel</v-btn
          > -->
          <v-btn
            color="#3F053C"
            class="buttons1"
            dark
            @click="deleteStaff()"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog width="800px" v-model="editdialogue">
      <v-card width="800px" class="pa-2">
        <v-layout wrap justify-center>
                <v-flex xs11 text-center><span style="color:#3F053C;" class="menuhead1">Edit Item</span></v-flex>
                <v-flex xs1 text-right>
                  <v-icon
                  @click="editdialogue = false"
                    color="#3F053C"
                    >mdi-close-box</v-icon
                  ></v-flex
                >
                <v-flex xs8 text-center py-4 >
              <v-divider></v-divider>
            </v-flex>
              </v-layout>
        <v-layout wrap px-4>
          <!-- <v-flex xs12 sm3 py-1 pr-2>
              <v-layout wrap v-if="curitem.itemId">
                <v-flex xs12 class="tablefont" text-left pr-2> <span>Item</span></v-flex>
                <v-flex xs12 pr-2 v-if="curitem.itemId.name">
                  <v-autocomplete
                    :items="ItemList"
                    v-model="curitem.itemId"
                    placeholder="Select Item"
                    item-text="name"
                    item-value="_id"
                    outlined
                    @input="getDesign2()"
                    color="#b234a9"
                    hide-details
                    dense
                  ></v-autocomplete
                ></v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm3 py-1 pr-2>
              <v-layout wrap v-if="curitem.designId">{{ curitem.designId.name }}
                <v-flex xs12 pr-2 class="subhed2"> <span>Design</span></v-flex>
                <v-flex xs12 pr-2 v-if="curitem.designId.name">
                  <v-select
                    color="#b234a9"
                    :items="designList2"
                    v-model="curitem.designId.name"
                    placeholder="Select Design"
                    item-text="name"
                    item-value="_id"
                    outlined
                    @input="(getSupplier()),(checktype(purchaseItem.design))"
                    hide-details
                    dense
                  ></v-select>
                </v-flex>
              </v-layout>
            </v-flex> -->
          <!-- <v-flex xs12 sm3 py-1 pr-2>
              <v-layout wrap>
                <v-flex xs12 class="subhed2" pr-2> <span>Supplier</span></v-flex>
                <v-flex xs12 pr-2>
                  <v-autocomplete
                    :items="SupplierList"
                    v-model="curitem.supplierId"
                    placeholder="Select supplier"
                    item-text="supplierName"
                    item-value="_id"
                    outlined @input="checkQty()"
                    color="#b234a9"
                    hide-details
                    dense
                  ></v-autocomplete
                ></v-flex>
              </v-layout>
            </v-flex> -->
          <v-flex xs12 sm4 py-1 pr-2 v-if="curitem.designId">
            <v-layout wrap v-if="curitem.designId.countOrSplit=='Count'">
              <v-flex xs12 class="tablefont" text-left pr-2> <span>Quantity </span></v-flex>
              <v-flex xs12 pr-2>
                <v-text-field
                  v-model="curitem.quantity"
                  outlined
                  type="number" 
                  :rules="[maxValueRule4(curitem.purchaseItemId.count)]"
                  color="#b234a9" @input="caledit1()"
                  placeholder="Quantity "
                  dense
                  hide-details="auto"
                ></v-text-field
              ></v-flex>
            </v-layout>
            <v-layout wrap v-else>
              <v-flex xs12 class="tablefont" text-left pr-2> <span>Quantity </span></v-flex>
              <v-flex xs12 pr-2>
                <v-text-field
                  outlined disabled
                  type="number"
                  color="#b234a9"
                  placeholder="0 "
                  dense
                  hide-details
                ></v-text-field
              ></v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm4 py-1 pr-2>
            <v-layout wrap>
              <v-flex xs12 class="tablefont" text-left pr-2> <span>VA (%) </span></v-flex>
              <v-flex xs12 pr-2>
                <v-text-field
                  v-model="curitem.valueAddition"
                  outlined
                  type="number"
                  @input="editcal1()"
                  color="#b234a9"
                  placeholder="VA "
                  dense
                  hide-details
                ></v-text-field
              ></v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm4 py-1 pr-2>
            <v-layout wrap>
              <v-flex xs12 class="tablefont" text-left pr-2>
                <span>Boarding Rate(₹)</span></v-flex
              >
              <v-flex xs12 pr-2>
                <v-text-field
                  disabled
                  type="number"
                  v-model="curitem.goldRate"
                  outlined
                  color="#b234a9"
                  placeholder="Boarding Rate "
                  dense
                  hide-details
                ></v-text-field
              ></v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm4 py-1 pr-2>
            <v-layout wrap>
              <v-flex xs12 class="tablefont" text-left pr-2>
                <span>Net Wt.(gm)</span></v-flex
              >
              <v-flex xs12 pr-2>
                <v-text-field
                  v-model="curitem.netWeight"
                  outlined
                 :disabled="tsttype=='Count'?true:false"
                  type="number"
                  @input="editcal1()"
                  color="#b234a9"
                  placeholder="Weight"
                 dense hide-details
                 
                ></v-text-field> 
                <!-- :rules="[maxValueRule5(curitem.purchaseItemId.netWeight)]" -->
            </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm4 py-1 pr-2>
            <v-layout wrap>
              <v-flex xs12 class="tablefont" text-left pr-2>
                <span>Making Charge(₹)</span></v-flex
              >
              <v-flex xs12 pr-2>
                <v-text-field
                  v-model="curitem.makingCharge"
                  outlined
                  type="number"
                  color="#b234a9"
                  placeholder="Making Charge"
                  dense
                  disabled
                  hide-details
                ></v-text-field
              ></v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm4 py-1 pr-2>
            <v-layout wrap>
              <v-flex xs12 class="tablefont" text-left pr-2>
                <span>Discount(₹)</span></v-flex
              >
              <v-flex xs12 pr-2>
                <v-text-field
                  v-model="curitem.discountAmount"
                  outlined
                  type="number"
                  color="#b234a9"
                  placeholder="Discount"
                  dense
                  hide-details
                ></v-text-field
              ></v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-card-actions class="pt-3">
         
          <!-- <v-btn
            color="grey"
            text
            class="body-2 font-weight-bold"
            @click="editdialogue = false"
            >Cancel</v-btn
          > -->
          <v-layout wrap>
            <v-spacer></v-spacer>
            <v-flex xs2 mx-4>
              <v-btn
            color="#3F053C"
            class="buttons1"
            dark block
            @click="editcal2()"
            >OK</v-btn
          >
            </v-flex>
          </v-layout>
        
        </v-card-actions>
      </v-card>
      <!-- </v-form> -->
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["title", "datareload", "customerName", "contact"],
  data() {
    return {
      quotation: "1",
      appLoading: false,
      ServerError: false,
      showsnackbar: false,
      timeout: 2000,
      msg: null,
      estno: null,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      juwellaryId: localStorage.getItem("juwellaryId"),
      itemz: [],
      designList: [],
      designList2: [],
      SupplierList: [],
      purchaseItem: {
        ItemCode: null,
        supplierCode: null,
        design: null,
        quantity: "",
        va: "",
    // taxType: localStorage.getItem("GSTstatus"),
        // boardingRate: localStorage.getItem("goldRate"),
        boardingRate: "",

        makingCharge: "",
        netweight: "",
        discount: "",
        newVA: "",
        newmakingcharge: "",
        itemTotalAmount: "",
        purchaseId: "",
        purchaseitemId: "",
        amountWithoutGst:"",
        amountWithGst:"",
      },
      singleitem: {
        itemId: null,
        supplierId: null,
        designId: null,
        quantity: "",
        valueAddition: "",
        goldRate: "",
        makingCharge: "",
        netWeight: "",
        discountAmount: "",
        adjustedMakingCharge: "",
        adjustedVA: "",
        itemTotalAmount: "",
        purchaseId: "",
        purchaseitemId: "",
        amountWithoutGst:"",
        amountWithGst:"",
      },
      List: [],
      saledata: [],
      ItemList: [],
      dtype: false,
      curitem: {},
      selectedItemWt: 0,
      itemz2: [],
      purchaseList: [],
      weightList: [],
      editdialogue: false,
      curId: null,
      dialoge: false,
      total3: "",
      stockCount: "",
      itempresent: false,
      stst1: false,
      stst2: false,
      itemPerWeight: 0,
      totNetWeight: "",
      gstpercent:0,
      billType:"DEMO",
      stockcount1:"",
      totNetWeight1:"",
      tsttype:"",
    };
  },
  computed: {
    taxType() {
      return localStorage.getItem("GSTstatus");
    },
    goldRate1() {
      return localStorage.getItem("goldRate");
    },
  },
  mounted() {
    this.getItem();
    this.purchaseItem.boardingRate=this.goldRate1;
    console.log("purchaseItem.boardingRate",this.purchaseItem.boardingRate);
    console.log("goldRate1",this.goldRate1)
    if(this.taxType=='true'){
    this.billType=='GST'
    }

  },
  watch: {
    title() {
      this.getData();
    },
    billType(){
      console.log("billType==",this.billType)
    },
    datareload() {
      console.log("datareload==", this.datareload);
      if (this.datareload == true) {
        this.getData();
      }
    },
    itemPerWeight() {
      this.cal3();
    },
    customerName() {
      console.log("customerName==", this.customerName);
      if (!this.customerName) {
        this.stst1 = false;
      } else {
        this.stst1 = true;
      }
      console.log("tstt=====",this.stst1)
    },
    contact() {
      console.log("contact==", this.contact);
      if (!this.contact) {
        this.stst2 = false;
      } else {
        this.stst2 = true;
      }
    },
    saledata() {
      this.$emit("stepper", {
        ref: "customerData",
        saledata: this.saledata,
        check1: true,
      });
    },
    itempresent() {
      this.$emit("stepper", {
        ref: "itempresent",
        itempresent: this.itempresent,
      });
    },
  },
  methods: {
    maxValueRule(value) {
      if (value > 100) {
        return "Maximum value is 100";
      }
      return true;
    },
    maxValueRule2(item) {
      return (value) => {
        if (value > item) {
          return `Maximum value is ${item}`;
        }
        return true;
      };
    },
    maxValueRule3(item) {
      return (value) => {
        if (value > item) {
          return `Maximum value is ${item}`;
        }
        return true;
      };
    },
    maxValueRule4(item) {
      return (value) => {
        if (value > item) {
          return `Maximum value is ${item}`;
        }
        else if (value <= 0) {
          return `Minimum value is 1`;
        }
        return true;
      };
    },
    maxValueRule5(item) {
      if(item){
      return (value) => {
        if (value > item) {
          return `Maximum value is ${item}`;
        }
        return true;
      };
    }
    },
    customFilter (item, queryText) {
        const textOne = item.function.toLowerCase()
        const textTwo = item.description.toLowerCase()
        const searchText = queryText.toLowerCase()
        return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
    },
    setDefault() {
      {
        this.$emit("stepper", {
          ref: "addedItem",
          items1: this.itemz,
          saledata: this.saledata,
          check: true,
        });
      }
    },
    validate() {
      console.log("333333")

      if (this.stst1 == false) {
        this.msg = "Please provide customer name";
        this.showsnackbar = true;
        return;
      } else if (this.stst2 == false) {
        this.msg = "Please enter contact";
        this.showsnackbar = true;
        return;
      }  else if (!this.purchaseItem.ItemCode) {
        this.msg = "Please choose item";
        this.showsnackbar = true;
        return;
      }else if (!this.purchaseItem.design) {
        this.msg = "Please choose design";
        this.showsnackbar = true;
        return;
      }  else if (!this.purchaseItem.supplierCode) {
        this.msg = "Please choose supplier";
        this.showsnackbar = true;
        return;
      } else if (this.purchaseItem.quantity>this.stockcount1) {
        console.log("stockcount1=",this.stockcount1)
        console.log("purchaseItem.quantity",this.purchaseItem.quantity)

        // if( this.purchaseItem.quantity>this.stockcount1){
          this.msg = "Please enter valid quantity1";
        this.showsnackbar = true;
        // }
        // if(this.purchaseItem.quantity<0 ){
        //   this.msg = "Please enter valid quantity2";
        // this.showsnackbar = true;
        // }
        // console.log("purchaseItem.quantity",this.purchaseItem.quantity)

      
        return;
      }  else if (!this.purchaseItem.va) {
        this.msg = "Please enter VA";
        this.showsnackbar = true;
        return;
      } 
      else if (this.purchaseItem.quantity && this.purchaseItem.quantity > this.stockcount1) {
        this.msg = "Please enter valid quantity";
        this.showsnackbar = true;
        return;
      } 
      else if (this.purchaseItem.quantity && this.purchaseItem.quantity < 0) {
        this.msg = "Please enter valid quantity2";
        this.showsnackbar = true;
        return;
      } 
      else if (this.purchaseItem.va>100) {
        this.msg = "Please enter valid VA";
        this.showsnackbar = true;
        return;
      }else if (!this.purchaseItem.boardingRate || this.purchaseItem.boardingRate==0) {
        this.msg = "Boarding rate not found";
        this.showsnackbar = true;
        return;
      } else if (this.purchaseItem.netweight&&this.purchaseItem.netweight<0) {
        this.msg = "Please enter valid Net weight";
        this.showsnackbar = true;
        return;
      }else if (this.purchaseItem.netweight&&this.purchaseItem.netweight>this.totNetWeight1) {
        this.msg = "Please enter valid Net weight";
        this.showsnackbar = true;
        return;
      }else if (!this.purchaseItem.netweight || this.purchaseItem.netweight=='0') {
        this.msg = "Please enter Net weight";
        this.showsnackbar = true;
        return;
      }else if (!this.purchaseItem.makingCharge) {
        this.msg = "Please enter making charge";
        this.showsnackbar = true;
        return;
      } else if (this.purchaseItem.discount && this.purchaseItem.discount>=this.purchaseItem.makingCharge) {
        this.msg = "Discount amount cannot be greater than making charge";
        this.showsnackbar = true;
        return;
      } 
      else {
        console.log("4444")
        this.addValues();
      }
    },
    addValues() {
      console.log("555544")

      this.singleitem.itemId = this.purchaseItem.ItemCode;
      this.singleitem.supplierId = this.purchaseItem.supplierCode;
      // this.singleitem.designId = this.purchaseItem.design._id.design._id;
      this.singleitem.designId = this.purchaseItem.design._id;

      this.singleitem.quantity = this.purchaseItem.quantity;
      this.singleitem.valueAddition = this.purchaseItem.va;
      this.singleitem.goldRate = this.purchaseItem.boardingRate;
      this.singleitem.makingCharge = this.purchaseItem.makingCharge;
      this.singleitem.netWeight = this.purchaseItem.netweight;
      this.singleitem.discountAmount = this.purchaseItem.discount;
      this.singleitem.itemTotalAmount = this.purchaseItem.itemTotalAmount;
      this.singleitem.adjustedMakingCharge = this.purchaseItem.newmakingcharge;
      this.singleitem.adjustedVA = this.purchaseItem.newVA;
      this.singleitem.purchaseId = this.purchaseItem.purchaseId;
      this.singleitem.purchaseitemId = this.purchaseItem.purchaseitemId;

      this.addVariant();
    },
    deleteStaff() {
      axios({
        url: "/saleitem/delete",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.curId,
        },
      })
        .then((response) => {
          this.dialoge = false;
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.dialoge = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    addVariant() {
      this.itemz.push(JSON.parse(JSON.stringify(this.singleitem)));
      console.log("itemz=", this.itemz);
      //////
      this.purchaseItem.ItemCode = null;
      this.purchaseItem.supplierCode = null;
      this.purchaseItem.design = null;
      this.purchaseItem.quantity = null;
      this.purchaseItem.va = null;
      this.purchaseItem.netweight = null;
      this.purchaseItem.makingCharge = null;
      this.purchaseItem.newmakingcharge = null;
      this.purchaseItem.discount = null;
      this.purchaseItem.newVA = null;
      this.purchaseItem.itemTotalAmount = null;
      this.stockCount="";
      this.setDefault();
      this.itemz = [];
    },
    changebillgst(){
this.billType="GST";
localStorage.setItem("billType",this.billType)
if(this.purchaseItem.itemTotalAmount)
this.cal2();
    },
    changebillnongst(){
this.billType="DEMO";
localStorage.setItem("billType",this.billType)
if(this.purchaseItem.itemTotalAmount)
this.cal2();
 
    },
    cal1() {
      var tst1 = this.purchaseItem.boardingRate * this.purchaseItem.netweight;
      console.log("tst1",tst1);
      var tst2 = this.purchaseItem.va / 100;
      console.log("tst2",tst2);

      this.purchaseItem.makingCharge = tst1 * tst2;
      console.log("this.purchaseItem.makingCharge",this.purchaseItem.makingCharge);

      this.cal2();
    },
    cal2() {
      console.log("cal2 called");
        //if discount present
      if (this.purchaseItem.discount > 0) {
        this.purchaseItem.newmakingcharge =
          this.purchaseItem.makingCharge - this.purchaseItem.discount;
        console.log("newmakingcharge=", this.purchaseItem.newmakingcharge);
        var onepercentoftotal =
          this.purchaseItem.boardingRate * this.purchaseItem.netweight * 0.01;
        this.purchaseItem.newVA =
          this.purchaseItem.newmakingcharge / onepercentoftotal;
        console.log("newVA=", this.purchaseItem.newVA);
        this.total2 =
          this.purchaseItem.boardingRate * this.purchaseItem.netweight +
          this.purchaseItem.newmakingcharge;
        console.log("withdiscounttotal=", this.total2);
        // calculating total with gst
        if(this.gstpercent && this.billType=='GST'){
          console.log("discount-total==",this.total2)
          var newamt = this.total2*(this.gstpercent/100);
          console.log("newamt==",newamt)
          var newamt2 =newamt+this.total2;
        this.purchaseItem.itemTotalAmount = newamt2;
        console.log("discount-gst-total==",newamt2)

        this.purchaseItem.amountWithGst=newamt2;
        }
        else{
        this.purchaseItem.itemTotalAmount = this.total2;
        }
        this.purchaseItem.amountWithoutGst=this.total2;

        //
      } else {
        //if no discount
        this.total1 =
          this.purchaseItem.boardingRate * this.purchaseItem.netweight +
          this.purchaseItem.makingCharge;
        console.log("withoutdiscounttotal=", this.total1);
         // calculating total with gst
         if(this.gstpercent && this.billType=='GST'){
          var newamt3 = this.total1*(this.gstpercent/100);
          var newamt4 =newamt3+this.total1;
        this.purchaseItem.itemTotalAmount = newamt4;
        console.log("nodiscount-gst-total==",newamt4)
        this.purchaseItem.amountWithGst=newamt4;

        }
        else{
        this.purchaseItem.itemTotalAmount = this.total1;
        }
        this.purchaseItem.amountWithoutGst=this.total1;

      }
      // this.addValues();
    },
    cal3() {
      if (this.purchaseItem.quantity) {
        // alert("1")
        this.purchaseItem.netweight =
          this.purchaseItem.quantity * this.itemPerWeight;
          console.log("itemPerWeight===",this.itemPerWeight)
          console.log("quantity===",this.purchaseItem.quantity)

          console.log("netwt===",this.purchaseItem.netweight)
          this.cal1();
      }
       else {
        this.purchaseItem.netweight="";
        // this.purchaseItem.netweight = this.itemPerWeight;
        // alert("2")
      }
    },
    caledit1() {
      this.curitem.netWeight=this.curitem.quantity*this.curitem.itemPerWeight;
      console.log("curitem.netwt==",this.curitem.netWeight)
      this.editcal1();
    },
    editcal1() {
      var tst1 = this.curitem.goldRate * this.curitem.netWeight;
      var tst2 = this.curitem.valueAddition / 100;
      this.curitem.makingCharge = tst1 * tst2;
      // this.editcal2();
    },
    editcal2() {
      if(this.curitem.netWeight>this.curitem.purchaseItemId.netWeight){
        this.msg = "Net weight cannot be greater than" + this.curitem.purchaseItemId.netWeight;
        this.showsnackbar = true;
        return;
      }
      if(this.curitem.netWeight<=0){
        this.msg = "Invalid Net weight";
        this.showsnackbar = true;
        return;
      }
      
      if(this.curitem.discountAmount>=this.curitem.makingCharge){
        this.msg = "Invalid Discount Amt";
        this.showsnackbar = true;
        return;
      }
      else{
      console.log("curitem.discount==", this.curitem.discountAmount);
      if (this.curitem.discountAmount > 0) {
        //if discountAmount present
        this.curitem.adjustedMakingCharge =
          this.curitem.makingCharge - this.curitem.discountAmount;
        console.log("newmakingcharge=", this.curitem.adjustedMakingCharge);
        var onepercentoftotal =
          this.curitem.goldRate * this.curitem.netWeight * 0.01;
        this.curitem.adjustedVA =
          this.curitem.adjustedMakingCharge / onepercentoftotal;
        console.log("newVA=", this.curitem.adjustedVA);
        this.total3 =
          this.curitem.goldRate * this.curitem.netWeight +
          this.curitem.adjustedMakingCharge;
        console.log("withdiscounttotal2=", this.total3);
        this.curitem.itemTotalAmount = this.total3;
      } else {
        //if no discount
        var total1 =
          this.curitem.goldRate * this.curitem.netWeight +
          this.curitem.makingCharge;
        console.log("withoutdiscounttotal3=", total1);
        this.curitem.itemTotalAmount = total1;
      }
      this.editItem();
    }
    },
    checkQty() {
      this.purchaseItem.quantity="";
      // this.appLoading = true;
      axios({
        method: "POST",
        url: "/itemcount",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          jewelleryId: this.juwellaryId,
          // designId: this.purchaseItem.design._id.design._id,
          designId: this.purchaseItem.design._id,

          itemId: this.purchaseItem.ItemCode,
          supplierId: this.purchaseItem.supplierCode,
        },
      })
        .then((response) => {
          // this.appLoading = false;
          if (response.data.status == true) {
            this.stockWt = response.data.totNetWeight;
            // var stockcount1 = response.data.data[0].newquantity;
            var stockcount1 = response.data.quantity;
            this.stockcount1  = response.data.quantity;
            console.log("stockcount1",stockcount1)
            this.stockCount =
              "Available count : " + JSON.stringify(stockcount1);
            console.log("stockCountprint=",this.stockCount)
            // this.itemPerWeight = response.data.itemPerWeight;
            // var totNetWeight1 = response.data.data[0].newnetwieght;
            this.totNetWeight1 = response.data.netWeight;

            this.totNetWeight =
              "Available weight : " + JSON.stringify(this.totNetWeight1)+ "gm";
            console.log("totNetWeight ", this.totNetWeight);
            this.Pagelength = Math.ceil(response.data.count / 20);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          } 

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    checktype(item) {
      console.log("item choosed=", item);
      var singleobj = this.designList.find((x) => x._id.id == item.id);
      console.log("singleobj==", singleobj);

      console.log("singleobj.countOrSplit==", singleobj._id.design.countOrSplit);
      if (singleobj._id.design.countOrSplit == "Count") {
        this.dtype = true;
        console.log("dtype=", this.dtype);
      } else {
        this.dtype = false;
        console.log("dtype=", this.dtype);
      }
      this.itemPerWeight=singleobj._id.itemPerWeight;
      console.log("itemPerWeight====",this.itemPerWeight)
    },
    calNetWt() {
      this.purchaseItem.netweight =
        this.selectedItemWt * this.purchaseItem.quantity;
    },
    getData() {
      axios({
        url: "/sales/getlist",
        method: "get",
        params: {
          billNo: this.title,
          status: "Pending",
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.List = response.data.data;
            if(this.List){
              if (this.List.length > 0) {
                this.itempresent = true;
                console.log("qq1")
              } else {
                this.itempresent = false;
                console.log("qq2")
              }
            }
            else{
              this.itempresent = false;
                console.log("qq2")
            }
            // this.saleId = response.data.saledata._id;
            this.saledata = response.data.saledata;
          } else {
            this.msg = response.data.msg;
            // this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getItem() {
      axios({
        method: "POST",
        url: "/item/getlist/purchased",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          jewelleryId: this.juwellaryId,
          page: this.page,
          count: 20,
        },
      })
        .then((response) => {
          // this.appLoading = false;
          if (response.data.status == true) {
            this.ItemList = response.data.allitemlist;
            this.Pagelength = Math.ceil(response.data.count / 20);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getSupplier() {
      // this.appLoading = true;
      // this.purchaseItem.design=null;
      this.purchaseItem.supplierCode="";
      // this.purchaseItem.purchaseId=null;
      this.purchaseItem.quantity="";
      // this.purchaseItem.va=0;
      // this.purchaseItem.netweight=0;
      // this.purchaseItem.makingCharge=0;
      // this.purchaseItem.discount=0;
      axios({
        method: "Post",
        url: "/supplier/getlist/purchased",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          jewelleryId: this.juwellaryId,
          designId: this.purchaseItem.design._id,
          itemId: this.purchaseItem.ItemCode,
        },
      })
        .then((response) => {
          // this.appLoading = false;
          if (response.data.status == true) {
            this.SupplierList = response.data.supplierlist;
            this.gstpercent = response.data.gst;
            console.log("gstpercent=",this.gstpercent)
      
            this.Pagelength = Math.ceil(response.data.count / 20);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getDesign() {
      this.designList=[];
      this.purchaseItem.quantity="";
      this.purchaseItem.supplierCode="";
      this.purchaseItem.design="";

      // this.appLoading = true;
      axios({
        method: "POST",
        url: "/design/getlist/purchased",
        // url: "/stock/getalllist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          jewelleryId: this.juwellaryId,
          itemId: this.purchaseItem.ItemCode,
        },
      })
        .then((response) => {
          // this.appLoading = false;
          if (response.data.status == true) {
            this.designList = response.data.data;
            this.Pagelength = Math.ceil(response.data.count / 20);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    // getDesign2() {
    //   // this.appLoading = true;
    //   axios({
    //     method: "POST",
    //     url: "/design/getlist/purchased",
    //     headers: {
    //       "x-auth-token": localStorage.getItem("token"),
    //     },
    //     data: {
    //       jewelleryId: this.juwellaryId,
    //       itemId: this.curitem.itemId,
    //     },
    //   })
    //     .then((response) => {
    //       // this.appLoading = false;
    //       if (response.data.status == true) {
    //         this.designList2 = response.data.alldesignlist;
    //         this.Pagelength = Math.ceil(response.data.count / 20);
    //       } else {
    //         this.snackbar = true;
    //         this.msg = response.data.msg;
    //       }

    //       // this.msg = response.data.msg;

    //       // this.snackbar = true;
    //     })
    //     .catch((err) => {
    //       this.appLoading = false;
    //       console.log(err);
    //     });
    // },
    getPurchase() {
      // this.appLoading = true;
      axios({
        method: "POST",
        url: "/billnumber/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          jewelleryId: this.juwellaryId,
          itemId: this.purchaseItem.ItemCode,
          designId: this.purchaseItem.design._id.design._id,
          supplierId: this.purchaseItem.supplierCode,
        },
      })
        .then((response) => {
          // this.appLoading = false;
          if (response.data.status == true) {
            this.purchaseList = response.data.data;
            this.Pagelength = Math.ceil(response.data.count / 20);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getWeight() {
      axios({
        method: "POST",
        url: "/billbased/items/",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          jewelleryId: this.juwellaryId,
          page: this.page,
          count: 20,
          purchaseId: this.purchaseItem.purchaseId,
        },
      })
        .then((response) => {
          // this.appLoading = false;
          if (response.data.status == true) {
            this.weightList = response.data.allpurchaseitems;
            this.Pagelength = Math.ceil(response.data.count / 20);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    editItem() {
      this.itemz2.push(JSON.parse(JSON.stringify(this.curitem)));
      console.log("itemz=", this.itemz);
      axios({
        url: "/sales/edit",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.curitem._id,
          items: this.itemz2,
        },
      })
        .then((response) => {
          this.editdialogue = false;
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.itemz2 = [];
            this.curitem = {};
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.txtfield2{
  border-color: #757575;
  border-style: solid;
  border-width: 1px;
  border-radius: 6px !important;
}
</style>