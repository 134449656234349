<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#3F033B"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showsnackbar" color="#3F033B" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12>
        <v-layout wrap>
          <v-flex xs12 sm6 md3 text-left>
            <span class="mainHeader3"> ESTIMATE :</span>
            <span class="mainHeader3"> {{ estno }}</span>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex xs12 sm6 md3 text-right>
            <span class="tst1" v-if="date"> Date : {{ date }}</span
            ><br />
            <span class="tst1" v-if="salesmenCode">
              Salesman : {{ salesmenCode }}</span
            >
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 pb-6 text-left>
        <!---------------------CUSTOMER DETAILS------------------------->

        <v-layout wrap>
          <v-flex xs12 py-2>
            <v-card
              tile
              elevation="0"
              class="rounded-lg"
              :color="$route.name == 'Dashboard' ? 'transparent' : '#ffffff'"
              :flat="$route.name == 'Dashboard' ? true : false"
            >
              <v-layout wrap>
                <v-flex xs12 pt-4 pl-4 class="mainHeader2">
                  Customer Details
                </v-flex>
              </v-layout>
              <v-layout wrap pa-4 class="tablefont0">
                <v-flex xs12 sm3 md2 pr-2>
                  <v-layout wrap>
                    <v-flex xs12> <span>Customer Name</span></v-flex>
                    <v-flex xs12>
                      <v-autocomplete
                        v-if="customerList"
                        color="#b234a9"
                        @input="customerdetails()"
                        :items="customerList"
                        v-model="customerName"
                        placeholder="Customer Name"
                        item-text="customerName"
                        item-value="_id"
                        outlined
                       
                        hide-details
                        dense
                      ></v-autocomplete>
                      <!-- <v-text-field v-else
                         v-model="customerName"
                        outlined
                        color="#b234a9"
                        placeholder="Customer"
                        dense
                        hide-details
                      ></v-text-field
                    > -->
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 sm3 md2 pr-2>
                  <v-layout wrap>
                    <v-flex xs12> <span>Address</span></v-flex>
                    <v-flex xs12>
                      <v-text-field
                        type="text"
                        v-model="address"
                        outlined
                        disabled
                        color="#b234a9"
                        placeholder="Address"
                        dense
                        hide-details
                      ></v-text-field
                    ></v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 sm3 md2 pr-2>
                  <v-layout wrap>
                    <v-flex xs12> <span>City</span></v-flex>
                    <v-flex xs12>
                      <v-text-field
                        type="text"
                        v-model="city"
                        outlined
                        disabled
                        color="#b234a9"
                        placeholder="City"
                        dense
                        hide-details
                      ></v-text-field
                    ></v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 sm3 md2 pr-2>
                  <v-layout wrap>
                    <v-flex xs12> <span>Mobile Number</span></v-flex>
                    <v-flex xs12>
                      <v-text-field
                        type="number"
                        v-model="contact"
                        outlined
                        disabled
                        color="#b234a9"
                        placeholder="Contact"
                        dense
                        hide-details="auto"
                      ></v-text-field
                    ></v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 sm3 md2 align-self-end text-left pr-2>
                  <v-icon large @click="addcustomerdialogue = true"
                    >mdi-account-plus</v-icon
                  >
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>

        <!---------------------ITEM DETAILS------------------------->

        <ESaddItem
          v-bind:title="estno"
          :datareload="datareload"
          :customerName="customerName"
          :contact="contact"
          @stepper="winStepper"
        />

        <oldGold
          v-if="itempresent == true"
          v-bind:title2="billData"
          @stepper="winStepper"
        />

        <returnGold
          v-if="itempresent == true"
          v-bind:title3="billData"
          @stepper="winStepper"
        />
        <v-layout wrap justify-end>
          <v-flex xs12 sm4 md2 py-4 v-if="itempresent == true">
            <!-- <v-layout wrap justify-end py-4>
              <v-flex xs3 pa-1>
                <v-btn dark color="#3F053C" block @click="submit()"
                  >Generate Quotation</v-btn
                ></v-flex
              >
              <v-flex xs12 sm2 pa-2 > -->
            <v-btn class="button1" dark color="#3F053C" block @click="validate()"
              >Generate Estimate</v-btn
            >
            <!-- </v-flex>
            </v-layout> -->
          </v-flex>
        </v-layout>
        <v-dialog width="800px" v-model="addcustomerdialogue">
          <v-form v-model="addcat" ref="addcat" @submit.prevent>
            <v-card tile width="1000px" class="px-8 py-4 rounded-lg">
              <v-layout wrap justify-center>
                <v-flex xs11 text-center><span style="color:#3F053C;" class="menuhead1">Add Customer</span></v-flex>
                <v-flex xs1 text-right>
                  <v-icon
                  @click="(addcustomerdialogue = false)(resetForm())"
                    color="#3F053C"
                    >mdi-close-box</v-icon
                  ></v-flex
                >
                <v-flex xs8 text-center py-4 >
              <v-divider></v-divider>
            </v-flex>
              </v-layout>
              <v-layout wrap pb-2>
                <v-flex xs6>
                  <v-layout wrap>
                <v-flex xs12>
                  <v-layout wrap px-4>
                    <v-flex xs12 text-left>
                      <span class="tablefont0"> Customer Name </span>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        type="text"
                        placeholder="Enter Full Name"
                        v-model="newCustomer"
                        outlined
                        dense
                        :rules="[rules.required]"
                        color="#b234a9"
                        hide-details="auto"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12>
                  <v-layout wrap px-4>
                    <v-flex xs12 text-left>
                      <span class="tablefont0">PAN Number</span>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        type="text"
                        placeholder="Enter PAN"
                        v-model="newCustomerPAN"
                        outlined
                        dense
                  :rules="panRules"
                        color="#b234a9"
                        hide-details="auto"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12>
                  <v-layout wrap px-4>
                    <v-flex xs12 text-left>
                      <span class="tablefont0">Aadhar Number</span>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        type="number"
                        placeholder="Enter Aadhar"
                        v-model="newCustomerAadhar"
                        outlined
                        dense
                  :rules="aadhaarRules"
                        color="#b234a9"
                        hide-details="auto"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12>
                  <v-layout wrap px-4>
                    <v-flex xs12 text-left>
                      <span class="tablefont0"> City </span>
                    </v-flex>
                    <v-flex xs12>
                      <v-flex xs12>
                        <v-text-field
                          color="#b234a9"
                          type="text"
                          placeholder="Enter City"
                          v-model="newCity"
                          outlined
                          dense
                          hide-details
                        ></v-text-field>
                      </v-flex>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12>
                  <v-layout wrap px-4>
                    <v-flex xs12 text-left>
                      <span class="subhead5"> Pin Code </span>
                    </v-flex>
                    <v-flex xs12>
                      <v-flex xs12>
                        <v-text-field
                          color="#b234a9"
                          type="number"
                          placeholder="Enter Pin"
                          v-model="newpinNumber"
                          outlined
                  :rules="pinRules"
                          dense
                          hide-details
                        ></v-text-field>
                      </v-flex>
                    </v-flex>
                  </v-layout>
                </v-flex>
                </v-layout>
                </v-flex>
                <v-flex xs6>
                  <v-layout wrap>
                    <v-flex xs12>
                  <v-layout wrap px-4>
                    <v-flex xs12 text-left>
                      <span class="tablefont0"> Address </span>
                    </v-flex>
                    <v-flex xs12>
                      <v-textarea
                        color="#b234a9"
                        type="text"
                        style="height: 168px;"
                        placeholder="Enter Address"
                        v-model="newAddress"
                        outlined
                        dense
                        hide-details
                      ></v-textarea>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12>
                  <v-layout wrap px-4>
                    <v-flex xs12 text-left>
                      <span class="tablefont0">Phone Number </span>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        placeholder="Contact"
                        v-model="newContact"
                        outlined
                        :rules="phoneRules"
                        color="#b234a9"
                        dense
                        type="number"
                        hide-details="auto"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
               
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-layout wrap pb-4 justify-end>
              <v-flex xs3>
                  <v-btn
                    type="submit"
                    color="#3F053C"
                    class="buttons1"
                    dark 
                    block
                    @click="AddCustomer()"
                    ><v-icon>mdi-plus</v-icon>Add</v-btn
                  >
                </v-flex>
              </v-layout>
            </v-card>
          </v-form>
        </v-dialog>
        <v-dialog
            style="z-index:3000;"
      v-model="preview"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
          <!-- <v-form v-model="addcat2" ref="addcat2" @submit.prevent> -->
            <v-layout wrap fill-height>
              <v-flex xs12>
                <v-card tile height="100%" class="px-8 py-4 rounded-lg card">
              <v-layout wrap px-4 justify-center>
                <v-flex xs12 sm4 py-1 text-left>
                  <v-layout wrap pb-1 justify-center>
                  <v-flex xs6 v-if="previewList2.customerName" class="subheeed3 text-left">Customer Name:</v-flex>
                  <v-flex xs6 pl-2 v-if="previewList2.customerName" class="subheeed33 text-left">{{previewList2.customerName}}</v-flex>
                </v-layout>
                <v-layout wrap pb-1 justify-center>
                  <v-flex xs6 v-if="previewList2.mobileNumber" class="subheeed3 text-left">Contact:</v-flex>
                  <v-flex xs6 pl-2 v-if="previewList2.mobileNumber" class="subheeed33 text-left">{{previewList2.mobileNumber}}</v-flex>
                </v-layout>
                <v-layout wrap pb-1 justify-center>
                  <v-flex xs6 v-if="previewList2.customerId" class="subheeed3 text-left">Address:</v-flex>
                  <v-flex xs6 pl-2 v-if="previewList2.customerId" class="subheeed33 text-left">{{previewList2.customerId.address}}</v-flex>
                </v-layout>
                </v-flex>
                <v-flex xs12 sm5 py-1 text-center>
                <v-layout wrap justify-center>
                  <v-flex xs10 align-self-center>
                    <span class="mainHeader2">Bill Preview</span
                  >
                  </v-flex>
                </v-layout>
                </v-flex>
                <v-flex xs12 sm3 py-1 >
                  <v-layout wrap pb-1 justify-center>
                  <v-flex xs6 v-if="previewList2.billNo" class="subheeed3 text-left">ESTIMATE NO:</v-flex>
                  <v-flex xs6 pl-2 v-if="previewList2.billNo" class="subheeed33 text-left">{{previewList2.billNo}}</v-flex>
                </v-layout>
                <v-layout wrap pb-1 justify-center>
                  <v-flex xs6 v-if="previewList2.staffCode" class="subheeed3 text-left">STAFF CODE:</v-flex>
                  <v-flex xs6 pl-2 v-if="previewList2.staffCode" class="subheeed33 text-left">{{previewList2.staffCode}}</v-flex>
                </v-layout>
                <v-layout wrap pb-1 justify-center>
                  <v-flex xs6 v-if="previewList2.create_date" class="subheeed3 text-left">Date:</v-flex>
                  <v-flex xs6 pl-2 v-if="previewList2.create_date" class="subheeed33 text-left">{{ formatDate(previewList2.create_date) }}</v-flex>
                </v-layout>

                </v-flex>
                <v-flex xs12 text-center py-2 style="color:#3F053C;">
              <v-divider></v-divider>
            </v-flex>
              </v-layout>
              <!-- <v-layout wrap pa-4>
                <v-flex xs12 sm6 py-1
                  >Customer Name: {{ customerName }}</v-flex
                >
                <v-flex xs12 sm6 py-1
                  >Contact: {{ contact }}</v-flex
                >
              </v-layout> -->
              <v-layout wrap px-4 py-2 v-if="previewList1">
                <v-flex xs12 py-1 text-left class="mainHeader2">Bill Items</v-flex>
                <v-flex xs12 py-1 text-left>
                  <v-simple-table class="tableborder">
                      <template v-slot:default>
                        <thead style="background-color:#e0e0e0">
                          <tr>
                            <th class="text-left tablefont"><b>S.No.</b></th>
                            <th class="text-left tablefont">
                              <b>Item</b>
                            </th>
                            <th class="text-left tablefont"><b>HSN</b></th>
                            <th class="text-left tablefont">
                              <b>Qty</b>
                            </th>
                            <th class="text-left">
                              <span class="text-left tablefont"><b>Gr.wt</b></span
                              ><span class="subheeed">(gm)</span>
                            </th>
                            <th class="text-left">
                              <span class="text-left tablefont"><b>St.wt</b></span
                              ><span class="subheeed">(gm)</span>
                            </th>
                            <th class="text-left">
                              <span class="text-left tablefont"><b>Net.wt</b></span
                              ><span class="subheeed">(gm)</span>
                            </th>
                            <th class="text-left">
                              <span class="text-left tablefont"><b>Mk.Chg</b></span
                              ><span class="subheeed">(₹)</span>
                            </th>
                            <th class="text-left">
                              <span class="text-left tablefont"><b>VA</b></span
                              ><span class="subheeed">(%)</span>
                            </th>
                            <th class="text-left">
                              <span class="text-left tablefont"><b>Discount</b></span
                              ><span class="subheeed">(₹)</span>
                            </th>
                            <!-- <th class="text-left">
                              <span class="text-left tablefont"><b>Ad.MK.Chg</b></span
                              ><span class="subheeed">(₹)</span>
                            </th>
                            <th class="text-left">
                              <span class="text-left tablefont"><b>Ad.VA</b></span
                              ><span class="subheeed">(%)</span>
                            </th> -->
                            <th class="text-left" v-if="taxType=='true'">
                              <span class="text-left tablefont"><b>CGST</b></span
                              ><span class="subheeed">(%)</span>
                            </th> <th class="text-left" v-if="taxType=='true'">
                              <span class="text-left tablefont"><b>SGST</b></span
                              ><span class="subheeed">(%)</span>
                            </th>
                            <th class="text-left">
                              <span class="text-left tablefont"><b>Total</b></span
                              ><span class="subheeed">(₹)</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(value, i) in previewList1" :key="i" class="table">
                            <td>
                              <span>
                                {{ i + 1 }}
                              </span>
                            </td>
                            <td>
                              <span v-if="value.designId"
                                >{{ value.designId.name }}-{{
                                  value.itemId.name
                                }}</span
                              >
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.designId.HSN">
                                {{ value.designId.HSN.hsnCode }}</span
                              >
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.quantity">{{
                                value.quantity
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.grossWeight">{{
                                value.grossWeight
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <td>
                              <span v-if="value.stoneWeight">{{
                                value.stoneWeight
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <td>
                              <span v-if="value.netWeight">{{
                                value.netWeight
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <td>
                              <span v-if="value.adjustedMakingCharge">{{ (value.adjustedMakingCharge).toFixed(2) }}</span>
                              <span v-else>{{
                                value.makingCharge.toFixed(2)
                              }}</span>
                            </td>
                            <td>
                              <span v-if="value.adjustedVA">{{ (value.adjustedVA).toFixed(2) }}</span>
                              <span v-else>{{
                                value.valueAddition.toFixed(2)
                              }}</span>
                            </td>
                            <!-- <td>
                              <span v-if="value.makingCharge">{{
                                value.makingCharge.toFixed(2)
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <td>
                              <span v-if="value.valueAddition">{{
                                value.valueAddition.toFixed(2)
                              }}</span>
                              <span v-else>0</span>
                            </td> -->
                            <td>
                              <span v-if="value.discountAmount">{{
                                value.discountAmount.toFixed(2)
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <!-- <td>
                              <span v-if="value.adjustedMakingCharge">{{
                                value.adjustedMakingCharge.toFixed(2)
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <td>
                              <span v-if="value.adjustedVA">{{
                                value.adjustedVA.toFixed(2)
                              }}</span>
                              <span v-else>0</span>
                            </td> -->
                            <td v-if="taxType=='true'" >
                              <span v-if="value.cgst">{{
                                value.cgst.toFixed(2)
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <td v-if="taxType=='true'" >
                              <span v-if="value.sgst">{{
                                value.sgst.toFixed(2)
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <td v-if="taxType=='true'" >
                              <span v-if="value.amountWithGst">{{
                                value.amountWithGst.toFixed(2)
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <td v-else>
                              <span v-if="value.amountWithoutGst">{{
                                value.amountWithoutGst.toFixed(2)
                              }}</span>
                              <span v-else>0</span>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                </v-flex>
              </v-layout>
              <v-layout wrap px-4 py-2 v-if="previewList3">
                <v-flex xs12  py-1 text-left class="mainHeader2" v-if="previewList3.length>0">Old Gold Items</v-flex>
                <v-flex xs12 py-1 text-left v-if="previewList3.length>0">
                  <v-simple-table class="tableborder">
                      <template v-slot:default>
                        <thead style="background-color:#e0e0e0">
                          <tr>
                            <th class="text-left tablefont"><b>S.No.</b></th>
                            <th class="text-left tablefont">
                              <b>Item</b>
                            </th>
                            <th class="text-left tablefont"><b>Rate(₹)</b></th>
                           
                            <th class="text-left tablefont"><b>Gr.wt(gm)</b></th>
                            <th class="text-left tablefont"><b>St.wt(gm)</b></th>
                            <th class="text-left tablefont"><b>Net.wt(gm)</b></th>
                            <th class="text-left tablefont"><b>Melt(%)</b></th>
                            <th class="text-left tablefont"><b>Amount(₹)</b></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(value, i) in previewList3" :key="i" class="table">
                            
                            <td>
                              <span>
                                {{ i + 1 }}
                              </span>
                            </td>
                            <td>
                              <span v-if="value.itemName"
                                >{{ value.itemName }}</span
                              >
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.goldRate">
                                {{ value.goldRate}}</span
                              >
                              <span v-else>-</span>
                            </td>
                            <!-- <td>
                              <span v-if="value.quantity">{{
                                value.quantity
                              }}</span>
                              <span v-else>-</span>
                            </td> -->
                            <td>
                              <span v-if="value.grossWeight">{{
                                value.grossWeight
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <td>
                              <span v-if="value.stoneWeight">{{
                                value.stoneWeight
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <td>
                              <span v-if="value.netWeight">{{
                                value.netWeight
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <td>
                              <span v-if="value.melt">{{
                                value.melt
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <td>
                              <span v-if="value.itemAmount">{{
                                value.itemAmount.toFixed(2)
                              }}</span>
                              <span v-else>0</span>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                </v-flex>
              </v-layout>
              <v-layout wrap px-4 py-2 v-if="previewList5">
                <v-flex xs12 py-1 text-left class="mainHeader2" v-if="previewList5.length>0">Return Items</v-flex>
                <v-flex xs12 py-1 text-left v-if="previewList5.length>0">
                  <v-simple-table class="tableborder">
                      <template v-slot:default>
                        <thead style="background-color:#e0e0e0">
                          <tr>
                            <th class="text-left tablefont"><b>S.No.</b></th>
                            <th class="text-left tablefont">
                              <b>Item</b>
                            </th>
                            <th class="text-left tablefont"><b>Qty(gm)</b></th>
                           
                            <th class="text-left tablefont"><b>Gr.wt(gm)</b></th>
                            <th class="text-left tablefont"><b>St.wt(gm)</b></th>
                            <th class="text-left tablefont"><b>Net.wt(gm)</b></th>
                            <!-- <th class="text-left tablefont"><b>St.pr(₹)</b></th> -->
                            <th class="text-left tablefont"><b>Total(₹)</b></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(value, i) in previewList5" :key="i" class="table">
                            
                            <td>
                              <span>
                                {{ i + 1 }}
                              </span>
                            </td>
                            <td>
                              <span v-if="value.designId"
                                >{{ value.designId.name }}-{{
                                  value.itemId.name
                                }}</span
                              >
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.quantity">
                                {{ value.quantity}}</span
                              >
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.grossWeight">{{
                                value.grossWeight
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <td>
                              <span v-if="value.stoneWeight">{{
                                value.stoneWeight
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <td>
                              <span v-if="value.netWeight">{{
                                value.netWeight
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <td>
                              <span v-if="value.itemTotalAmount">{{
                                value.itemTotalAmount.toFixed(2)
                              }}</span>
                              <span v-else>0</span>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                </v-flex>
              </v-layout>
              <v-layout wrap justify-start px-4>
                <v-flex xs12 sm4 py-1 text-left align-self-end>
                  <v-layout wrap pb-1 justify-center v-if="taxType==true">
                  <v-flex xs6 v-if="previewList2.totalAmountWithGst" class="subheeed3 text-left">Item Total(₹) :</v-flex>
                  <v-flex xs6 pl-2 v-if="previewList2.totalAmountWithGst" class="subheeed33 text-left">{{(previewList2.totalAmountWithGst).toFixed(2)}}</v-flex>
                </v-layout>
                <v-layout wrap pb-1 justify-center v-else>
                  <v-flex xs6 v-if="previewList2.totalAmountWithoutGst" class="subheeed3 text-left">Item Total(₹) :</v-flex>
                  <v-flex xs6 pl-2 v-if="previewList2.totalAmountWithoutGst && taxType=='true'" class="subheeed33 text-left">{{(previewList2.totalAmountWithGst).toFixed(2)}}</v-flex>
                  <v-flex xs6 pl-2 v-if="previewList2.totalAmountWithoutGst && taxType=='false'" class="subheeed33 text-left">{{(previewList2.totalAmountWithoutGst).toFixed(2)}}</v-flex>
                </v-layout>
                <v-layout wrap pb-1 justify-center>
                  <v-flex xs6 v-if="previewList2.oldGoldAmount" class="subheeed3 text-left">Old Gold Amt.(₹) :</v-flex>
                  <v-flex xs6 pl-2 v-if="previewList2.oldGoldAmount" class="subheeed33 text-left">{{(previewList2.oldGoldAmount).toFixed(2)}}</v-flex>
                </v-layout>
                <v-layout wrap pb-1 justify-center>
                  <v-flex xs6 v-if="returnTotalAmt" class="subheeed3 text-left">Return Amt.(₹) :</v-flex>
                  <v-flex xs6 pl-2 v-if="returnTotalAmt" class="subheeed33 text-left">{{(returnTotalAmt).toFixed(2)}}</v-flex>
                </v-layout>
                <v-layout wrap pb-1 justify-center>
                  <v-flex xs6 v-if="newTotal" class="subheeed3 text-left">Total Amt.(₹) :</v-flex>
                  <v-flex xs6 pl-2 v-if="newTotal" class="subheeed33 text-left">{{newTotal}}</v-flex>
                </v-layout>
                </v-flex>
                <v-flex xs12 sm8 py-1 text-right align-self-end>
                  <v-layout wrap py-1 justify-end>
                <v-flex xs2 px-1class="buttons1"> <v-btn dark color="#3F053C" block @click="preview=false"
              class="buttons1">Cancel</v-btn></v-flex>
                <v-flex xs3 mx-1 class="buttons1">
                  <v-btn dark color="#3F053C" block @click="saveBill()"
              class="buttons1">Proceed to payment</v-btn>
                  </v-flex>
              </v-layout>
                </v-flex>
              </v-layout>
             
            </v-card>
              </v-flex>
            </v-layout>
          
          <!-- </v-form> -->
        </v-dialog>
        <v-dialog
          v-model="queryPopUpDialog"
          persistent
          :width="
            $vuetify.breakpoint.name == 'xs'
              ? '100vw'
              : $vuetify.breakpoint.name == 'sm'
              ? '60vw'
              : $vuetify.breakpoint.name == 'md'
              ? '50vw'
              : $vuetify.breakpoint.name == 'lg'
              ? '40vw'
              : '30vw'
          "
          style="z-index: 1000"
        >
          <v-card tile color="#FFFFFF">
            <v-layout wrap justify-center pa-2>
              <v-flex xs12 text-left pa-4>
                <span class="mainHeader2">BOARDING RATE</span>
              </v-flex>
              <v-flex xs12 sm6 pa-4>
                <v-card>
                  <v-layout wrap pa-4 justify-center>
                    <v-flex xs12 py-2 class="mainhead2">
                      THANKAM Per gm
                    </v-flex>
                    <v-flex xs8 text-center>
                      <v-text-field
                        autofocus
                        placeholder="0"
                        class="my-text-field"
                        prefix="₹"
                        v-model="thankamRate"
                        type="number"
                        solo
                        flat
                        style="font-size: 30px"
                        dense
                        hide-details
                        height="60px"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex xs12 sm6 pa-4>
                <v-card>
                  <v-layout wrap pa-4 justify-center>
                    <v-flex xs12 py-2 class="mainhead2"> GOLD Per gm </v-flex>
                    <v-flex xs8 text-center>
                      <v-text-field
                        prefix="₹"
                        v-model="gold"
                        type="number"
                        style="font-size: 30px"
                        solo
                        placeholder="0"
                        class="my-text-field"
                        flat
                        dense
                        hide-details
                        height="60px"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex xs12 sm6 pa-4>
                <v-card>
                  <v-layout wrap pa-4 justify-center>
                    <v-flex xs12 py-2 class="mainhead2"> SILVER Per gm </v-flex>
                    <v-flex xs8 text-center>
                      <v-text-field
                        v-model="silverRate"
                        type="number"
                        solo
                        placeholder="0"
                        class="my-text-field"
                        prefix="₹"
                        style="font-size: 30px"
                        flat
                        dense
                        height="60px"
                        hide-details
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex xs12 sm6 pa-4>
                <v-card>
                  <v-layout wrap pa-4 justify-center>
                    <v-flex xs12 py-2 class="mainhead2">
                      PLATINUM Per gm
                    </v-flex>
                    <v-flex xs8 text-center>
                      <v-text-field
                        style="font-size: 30px"
                        prefix="₹"
                        v-model="platinumRate"
                        type="number"
                        solo
                        placeholder="0"
                        class="my-text-field"
                        flat
                        height="60px"
                        dense
                        hide-details
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex xs12 sm6 pa-4>
                <v-card>
                  <v-layout wrap pa-4 justify-center>
                    <v-flex xs12 py-2 class="mainhead2"> DIAMOND </v-flex>
                    <v-flex xs8 text-center>
                      <v-text-field
                        prefix="₹"
                        style="font-size: 30px"
                        v-model="diamond"
                        type="number"
                        solo
                        placeholder="0"
                        class="my-text-field"
                        dense
                        flat
                        hide-details
                        height="60px"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-spacer></v-spacer>
              <v-flex xs3 text-right pa-4 align-self-end>
                <v-btn
                  height="45px"
                  width="80px"
                  color="#3F053C"
                  class="body-2 font-weight-bold"
                  dark
                  block
                  @click="EditRate()"
                  >Save</v-btn
                >
              </v-flex>
            </v-layout>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import ESaddItem from "./ESaddItem";
import oldGold from "./oldGold";
import returnGold from "./returnGold";

export default {
  components: {
    ESaddItem,
    oldGold,
    returnGold,
  },
  data() {
    return {
      dialog:false,
      //bordingrate
      gold: "",
      thankamRate: "",
      silverRate: "",
      platinumRate: "",
      diamond: "",
      queryPopUpDialog: false,
      //
      quotation: "1",
      appLoading: false,
      ServerError: false,
      showsnackbar: false,
      timeout: 2000,
      msg: null,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu2: false,
      estno: null,
      value: "",
      newCustomer: null,
      newAddress: null,
      newCity: null,
      newContact: null,
      newpinNumber: null,
      newCustomerPAN: null,
      newCustomerAadhar: null,
      addcustomerdialogue: false,
      addcat: false,
      // bill:null,
      customerName: null,
      address: null,
      city: null,
      contact: null,
      salesmenCode: localStorage.getItem("staffCode"),
      billData: {},
      itemz: [],
      datareload: false,
      addcat2: false,
      purity: null,
      oldstoneweight: null,
      oldstoneprice: null,
      oldlessweight1: null,
      oldlessweight2: null,
      oldgrossweight: null,
      oldnetweight: null,
      oldprice: null,
      oldtotal: null,
      tax: null,
      saleweight: null,
      saleamount: null,
      oldfinalTotal: null,
      returnbillno: null,
      returndate: null,
      juwellaryId: localStorage.getItem("juwellaryId"),
      returnsalesId: null,
      returndata: [],
      olditems: [],
      categoryList: [],
      itempresent: false,
      returnbillNo: null,
      returncustomerName: null,
      returnstaffCode: null,
      returnTotalAmt: 0,
      returnNetWt: 0,
      customerList: [],
      returngoldRate: "",
      cid: "",
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",

        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      phoneRules: [
        (v) => !!v || "phone is required",
        (v) => (v && v.length <= 10) || "invalid number",
        (v) => (v && v.length >= 10) || "invalid number",
        (v) => /^\d{10}$/.test(v) || "phone number must be a number",
      ],
      panRules: [
  (value) => !!value || "PAN number is required.",
  (value) => /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(value) || "Invalid PAN number.",
],
pinRules: [
    (value) => !!value || "PIN number is required.",
    (value) => /^\d{6}$/.test(value) || "Invalid PIN number.",
  ],
aadhaarRules: [
  (value) => !!value || "Aadhaar number is required.",
  (value) => /^\d{12}$/.test(value) || "Invalid Aadhaar number.",
],
      //preview
      preview: false,
      previewList1:[],
      previewList2:[],
      previewList3:[],
      previewList4:[],
      previewList5:[],
      saleTTL:"",
      newTotal:"",
    };
  },
  beforeMount() {
    this.getCustomer();
    this.getBill();
    this.userinfo();
  },
  watch: {
    billData() {
      this.customerName = this.billData.customerId;
      this.address = this.billData.customerId.address;
      this.city = this.billData.customerId.city;
      this.contact = this.billData.customerId.mobileNumber;
    },
    cid() {
      console.log("cid==", this.cid);
      this.customerName = this.cid;
      this.customerdetails();
    },
  },
  computed: {
    taxType() {
      return localStorage.getItem("GSTstatus");
    },
  },
  methods: {
    resetForm() {
      this.$refs.addcat.reset();
    },
    userinfo() {
      axios({
        method: "GET",
        url: "/user/info",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          id: localStorage.getItem("id"),
        },
      })
        .then((response) => {
          // console.log("menu-loginpage");
          if (response.data.status) {
            var check = response.data.goldrateadded;
            if (check == false) {
              console.log("check===-=", check);
              this.queryPopUpDialog = true;
            }
          } else {
            console.log("estpage user info");
            return;
          }
        })
        .catch((err) => {
          var msg = err;
          console.log(msg);
        });
    },
    winStepper(item) {
      if (item.ref == "addedItem") {
        console.log("item==", item.items1);
        if (item.check == true || item.items1) {
          this.itemz = item.items1;
          this.addItem();
        }
      }
      if (item.ref == "customerData") {
        if (item.saledata) {
          this.billData = item.saledata;

          console.log("billData1==", this.billData);
        }
      }
      if (item.ref == "returnItems") {
        if (item.returndata || item.returnsalesId) {
          this.returngoldRate = item.returngoldRate;
          console.log("returngoldRate==", item.returngoldRate);
          this.returnTotalAmt = item.returnTotalAmt;
          console.log("returnTotalAmt==", item.returnTotalAmt);
          this.returnNetWt = item.returnNetWt;
          console.log("returnNetWt==", item.returnNetWt);
          this.returndata = item.returndata;
          console.log("returndata==", item.returndata);
          this.returnsalesId = item.returnsalesId;
          console.log("returnsalesId==", item.returnsalesId);
          this.returnbillNo = item.billNo;
          this.returncustomerName = item.customerName;
          this.returnstaffCode = item.staffCode;
        }
      }
      if (item.ref == "itempresent") {
        if (item.itempresent) {
          this.itempresent = item.itempresent;
          console.log("itempresent==", item.itempresent);
        }
      }
    },
    validate() {
      if (!this.customerName) {
        this.msg = "Please enter customer name ";
        this.showsnackbar = true;
      } else if (!this.contact) {
        this.msg = "Please enter contact number ";
        this.showsnackbar = true;
      } else {
        // this.saveBill();
        this.getpreviewbill();
        this.preview = true;
      }
    },
    calctotal(){
this.newTotal=0;
this.saleTTL=0;
console.log("tax==",this.taxType)
if(this.taxType=='true')
{
  this.saleTTL = this.previewList2.totalAmountWithGst
  console.log("this.previewList2.totalAmountWithGst=",this.saleTTL)
}
else{
  this.saleTTL = this.previewList2.totalAmountWithoutGst
  console.log("this.previewList2.totalAmountWithoutGst=",this.saleTTL)
}
if(this.previewList2.oldGoldAmount){
  this.saleTTL= this.saleTTL-this.previewList2.oldGoldAmount;
  console.log("this.previewList2.oldGoldAmount=",this.saleTTL)
}
if(this.returnTotalAmt){
  this.saleTTL= this.saleTTL-this.returnTotalAmt;
  console.log("this.previewList2.returnTotalAmt=",this.saleTTL)
}
var ttl = this.saleTTL.toFixed(2);
this.newTotal=ttl;
    },
    getBill() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/sales/bill/generate",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          jewelleryId: this.juwellaryId,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            // this.Details = response.data;
            this.estno = response.data.bill;
            console.log("estno=", this.estno);
            this.datareload = true;
            // this.getData();
            // this.Pagelength = Math.ceil(response.data.count / 20);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    addItem() {
      this.datareload = false;
      axios({
        url: "/sales/add",
        method: "POST",
        data: {
          items: this.itemz,
          isEdit:false,
          customerId: this.customerName,
          address: this.address,
          city: this.city,
          mobileNumber: this.contact,
          date: this.date,
          billNo: this.estno,
          staffCode: this.salesmenCode,
          jewelleryId: this.juwellaryId,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            console.log("if")

            this.datareload = true;

            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.email = null;
            this.phone = null;
            this.name = null;
            this.address = null;
            this.username = null;
            this.datareload = true;
          } if (response.data.status == false && response.data.isExist == true) {
            console.log("elseif-bill exists")
            this.msg = "Bill used by another user";
            this.showsnackbar = true;
            // this.getBill();
            setTimeout(() => {location.reload();}, "2000");
            

           }
            else {
            console.log("else")
            this.datareload = true;
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    saveBill() {
      this.datareload = false;
      axios({
        url: "/sales/generate",
        method: "POST",
        data: {
          customerId:this.customerName,
          returngoldRate: this.returngoldRate,
          saleId: this.billData._id,
          returnSaleId: this.returnsalesId,
          currentReturnItems: this.returndata,
          billNo: this.returnbillNo,
          // customerName: this.returncustomerName,
          staffCode: this.returnstaffCode,
          jewelleryId: this.juwellaryId,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.datareload = true;
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.email = null;
            this.phone = null;
            this.name = null;
            this.address = null;
            this.username = null;
            // this.printBill();
            // this.$router.push('/SalesSettlement?no='+this.billData._id)
            this.$router.push("/SalesList");
          } else {
            this.datareload = false;
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getCustomer() {
      axios({
        method: "get",
        url: "/customer/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          // this.appLoading = false;
          if (response.data.status == true) {
            this.customerList = response.data.data;
            this.Pagelength = Math.ceil(response.data.count / 20);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    // printBill() {
    //   axios({
    //     method: "get",
    //     url: "/download/invoice",
    //     headers: {
    //       "x-auth-token": localStorage.getItem("token"),
    //     },
    //     params: {
    //   id:this.billData._id,

    //     },

    //   })
    //     .then((response) => {
    //       // this.appLoading = false;
    //       if (response.data.status == true) {

    //       } else {
    //         this.snackbar = true;
    //         this.msg = response.data.msg;
    //       }

    //       // this.msg = response.data.msg;

    //       // this.snackbar = true;
    //     })
    //     .catch((err) => {
    //       this.appLoading = false;
    //       console.log(err);
    //     });
    // },
    customerdetails() {
      axios({
        method: "get",
        url: "/customer/view",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          id: this.customerName,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            // this.customerName = response.data.data;
            this.address = response.data.data.address;
            this.city = response.data.data.city;
            this.contact = response.data.data.mobileNumber;
          } else {
            this.msg = response.data.msg;
            this.snackbar = true;

            // if (this.snackbar == false) {
            //   this.$router.go();
            // }

            // setTimeout(() => this.$router.go(),3000)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    AddCustomer() {
      axios({
        url: "/customer/add",
        method: "POST",
        data: {
          customerName: this.newCustomer,
          address: this.newAddress,
          city: this.newCity,
          mobileNumber: this.newContact,
      pinNumber: this.newpinNumber,
          jewelleryId: this.juwellaryId,
          panNo: this.newCustomerPAN,
          aadharNo: this.newCustomerAadhar,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.addcustomerdialogue = false;
            this.getCustomer();
            this.cid = response.data.customerData._id;
            // customerdetails
            // this.customerName=this.newCustomer;
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.newCustomer = null;
            this.newAddress = null;
            this.newCity = null;
            this.newContact = null;
            this.newpinNumber = null;
            this.newCustomerPAN = null;
            this.newCustomerAadhar = null;
            this.$refs.addcat.resetValidation();
          } else {
            this.datareload = false;
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    EditRate() {
      axios({
        url: "/rate/add",
        method: "POST",
        data: {
          goldRate: this.gold,
          silverRate: this.silverRate,
          platinumRate: this.platinumRate,
          diamond: this.diamond,
          date: this.date,
          thankamRate: this.thankamRate,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.queryPopUpDialog = false;
            localStorage.setItem("diamond", response.data.data.diamond);
            localStorage.setItem("goldRate", response.data.data.goldRate);
            localStorage.setItem(
              "platinumRate",
              response.data.data.platinumRate
            );
            localStorage.setItem("silverRate", response.data.data.silverRate);
            localStorage.setItem("thankamRate", response.data.data.thankamRate);
            location.reload();

            // this.goldrate = null;
            // this.silverrate = null;
            // this.platinumrate = null;
            // this.diamondrate = null;
            this.date = null;
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    //for preview
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      // var hours = dt.getHours();
      // var minutes = dt.getMinutes();
      dt = dt.toString();
      // var ampm = hours >= 12 ? "pm" : "am";
      // hours = hours % 12;
      // hours = hours ? hours : 12;
      // minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = day + " " + dt.slice(4, 7) + " " + year;
      //  +
      // " , " +
      // hours +
      // ":" +
      // minutes +
      // " " +
      // ampm;

      return strTime;
    },
    getpreviewbill() {
      axios({
        url: "/sales/getlist",
        method: "get",
        params: {
          billNo: this.estno,
          status: "Pending",
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.previewList1 = response.data.data;
            this.previewList2 = response.data.saledata;
            this.calctotal();
          } else {
            this.msg = response.data.msg;
            // this.showsnackbar = true;
          }
        this.getpreviewOldgold();
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getpreviewOldgold() {
      axios({
        url: "/oldgolditem/getlist",
        method: "get",
        params: {
          salesId: this.billData._id,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.previewList3 = response.data.data;
            this.previewList4 = response.data.bill;
          }
        // this.getpreviewReturns();
        this.previewList5 = this.returndata;

        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.card {
  flex: 1; /* Expand the card to fill the remaining height */
  display: flex;
  flex-direction: column;
}
</style>