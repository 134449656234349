<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#3F033B"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showsnackbar" color="#3F033B" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12 py-4>
        <v-card
          tile
          elevation="0"
          class="rounded-lg"
          :color="$route.name == 'Dashboard' ? 'transparent' : '#ffffff'"
          :flat="$route.name == 'Dashboard' ? true : false"
        >
          <v-layout wrap pa-4 class="subhed3">
            <v-flex xs12 pr-2>
              <v-layout wrap>
                <v-flex xs8 class="mainHeader2 pb-4">Old Gold</v-flex>
                <v-spacer></v-spacer>

<v-btn
icon
@click="show = !show"
>
<v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
</v-btn>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-expand-transition>
      <div v-show="show">
          <v-layout wrap px-4 class="subhed3">
            <v-flex xs12 sm3 md2 pr-2>
              <v-layout wrap>
                <v-flex xs12 class="tablefont0" pr-2> <span>Item</span></v-flex>
                <v-flex xs12 pr-2>
                  <v-text-field
                    v-model="oldGold.itemName"
                    outlined
                    type="text"
                    color="#b234a9"
                    placeholder="Item Name"
                    dense
                    hide-details
                  ></v-text-field>
              </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm3 md2 pr-2>
              <v-layout wrap>
                <v-flex xs12 pr-2 class="tablefont0"> <span>GoldRate</span></v-flex>
                <v-flex xs12 pr-2>
                  <v-text-field
                    v-model="oldGold.goldRate"
                    outlined
                    type="number"
                    color="#b234a9"
                    placeholder="GoldRate"
                    dense
                    hide-details
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
            <!-- <v-flex xs12 sm3 md2 pr-2>
              <v-layout wrap>
                <v-flex xs12 class="tablefont0" pr-2> <span>Qty</span></v-flex>
                <v-flex xs12 pr-2>
                  <v-text-field
                    v-model="oldGold.quantity"
                    outlined
                    type="number"
                    @input="cal1()"
                    color="#b234a9"
                    placeholder="Quantity "
                    dense
                    hide-details
                  ></v-text-field>
              </v-flex>
              </v-layout>
            </v-flex> -->
            <v-flex xs12 sm3 md2 pr-2>
              <v-layout wrap>
                <v-flex xs12 class="tablefont0" pr-2>
                  <span>Gross.Wt(gm)</span></v-flex
                >
                <v-flex xs12 pr-2>
                  <v-text-field
                    v-model="oldGold.grossWeight"
                    outlined @input="cal1()"
                    type="number"
                    color="#b234a9"
                    placeholder="Gross.Wt "
                    dense
                    hide-details
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
           
            <v-flex xs12 sm3 md2 pr-2>
              <v-layout wrap>
                <v-flex xs12 class="tablefont0" pr-2>
                  <span>Stone.Wt(gm)</span></v-flex
                >
                <v-flex xs12 pr-2>
                  <!-- :rules="[maxValueRule(oldGold.grossWeight, oldGold.stoneWeight)]" -->
                  <v-text-field
                    type="number"
                    
                    v-model="oldGold.stoneWeight"
                    outlined @input="cal1()"
                    color="#b234a9"
                    placeholder="Stone.Wt"
                    dense
                    hide-details="auto"
                  ></v-text-field
                ></v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm3 md2 pr-2>
              <v-layout wrap>
                <v-flex xs12 class="tablefont0" pr-2> <span>Net.Wt(gm)</span></v-flex>
                <v-flex xs12 pr-2>
                  <v-text-field
                    v-model="oldGold.netWeight"
                    outlined disabled
                    type="number"
                    color="#b234a9"
                    placeholder="Net.Wt"
                    dense
                    hide-details
                  ></v-text-field
                ></v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm3 md2 pr-2>
              <v-layout wrap>
                <v-flex xs12 class="tablefont0" pr-2>
                  <span>melt(%)</span></v-flex
                >
                <v-flex xs12 pr-2>
                  <v-text-field
                    v-model="oldGold.melt"
                    outlined
                    type="number"
                    color="#b234a9"
                    placeholder="Melt"
                    dense
                    hide-details
                  ></v-text-field
                ></v-flex>
              </v-layout>
            </v-flex>
            <!-- <v-flex xs12 sm2 pr-2>
              <v-layout wrap>
                <v-flex xs12 class="subhed2" pr-2>
                  <span>Amount(₹)</span></v-flex
                >
                <v-flex xs12 pr-2>
                  <v-text-field
                    v-model="oldGold.itemAmount"
                    outlined
                    type="number"
                    color="#b234a9"
                    placeholder="Total"
                    dense
                    disabled
                    hide-details
                  ></v-text-field
                ></v-flex>
              </v-layout>
            </v-flex> -->
            <v-flex xs6 sm1 pr-2 align-self-end>
              <v-layout wrap>
                <v-flex xs6 pr-1 pb-0 pb-sm-2 pt-1>
                  <v-btn @click="validate()" color="#3F053C" dark small
                    ><v-icon>mdi-check</v-icon></v-btn
                  >
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>

          <!---------------------Table-------------------------->
          <v-layout px-4 py-4 wrap>
            <v-flex xs12 v-if="List">
              <v-card  v-if="List.length>0">
                <v-layout wrap>
                  <v-flex xs12 >
                    <v-simple-table>
                      <template v-slot:default>
                        <thead style="text-transform: uppercase">
                          <tr>
                            <th class="text-left tablefont"><b>S.No.</b></th>
                            <th class="text-left tablefont">
                              <b>Item</b>
                            </th>
                            <th class="text-left tablefont"><b>Rate(₹)</b></th>
                           
                            <th class="text-left tablefont"><b>Gr.wt(gm)</b></th>
                            <th class="text-left tablefont"><b>St.wt(gm)</b></th>
                            <th class="text-left tablefont"><b>Net.wt(gm)</b></th>
                            <th class="text-left tablefont"><b>Melt(%)</b></th>
                            <th class="text-left tablefont"><b>Amount(₹)</b></th>
                            <th class="text-left tablefont"><b>Action</b></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(value, i) in List" :key="i" class="table">
                            
                            <td>
                              <span>
                                {{ i + 1 }}
                              </span>
                            </td>
                            <td>
                              <span v-if="value.itemName"
                                >{{ value.itemName }}</span
                              >
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.goldRate">
                                {{ value.goldRate}}</span
                              >
                              <span v-else>-</span>
                            </td>
                            <!-- <td>
                              <span v-if="value.quantity">{{
                                value.quantity
                              }}</span>
                              <span v-else>-</span>
                            </td> -->
                            <td>
                              <span v-if="value.grossWeight">{{
                                value.grossWeight
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <td>
                              <span v-if="value.stoneWeight">{{
                                value.stoneWeight
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <td>
                              <span v-if="value.netWeight">{{
                                value.netWeight
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <td>
                              <span v-if="value.melt">{{
                                value.melt
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <td>
                              <span v-if="value.itemAmount">{{
                                value.itemAmount.toFixed(2)
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <td>
                              <v-icon
                                size="18"
                                class="pr-2"
                                color="#3F053C"
                                style="cursor: pointer"
                                @click="
                                  (curitem = value),
                                    (editdialogue = true)
                                "
                                >mdi-pencil</v-icon
                              >
                              <v-icon
                                size="18"
                                style="cursor: pointer"
                                @click="(dialoge = true), (curId = value._id)"
                                >mdi-delete-outline</v-icon
                              >
                            </td> 
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout wrap>
            <v-flex xs12 class="subhed2">
              <v-layout justify-end>
                <v-flex xs12 sm6 md2 px-1>
                  <v-layout wrap py-2>
                <v-flex xs12 text-left class="subhed2" pr-4 align-self-center v-if="saledata.totalNetWeight">
                  Net Weight(gm) 
                </v-flex>
                <v-flex xs12 pr-5 v-if="saledata.totalNetWeight">
                  <v-card
                    outlined
                    class="pa-2"
                    
                  >
                  {{saledata.totalNetWeight.toFixed(2)}}</v-card
                ></v-flex>
              </v-layout>
                </v-flex>
                <v-flex xs12 sm6 md2 px-1>
                  <v-layout wrap py-2 pb-4 v-if="saledata.totalAmount">
                <v-flex xs12 text-left class="subhed2" pr-4 align-self-center>
                  Net Amount(₹)
                </v-flex>
                <v-flex xs12 pr-5>
                  <v-card
                    outlined
                    class="pa-2"
                    
                  >
                  {{saledata.totalAmount.toFixed(2)}}</v-card
                ></v-flex>
              </v-layout>
                </v-flex>
              </v-layout>
             
           
            </v-flex>
          </v-layout>
        </div>
            </v-expand-transition>
        </v-card>
      </v-flex>
    </v-layout>
     <v-dialog width="400px" v-model="dialoge">
          <v-card width="400px" class="pa-2">
            <v-layout wrap justify-center>
                <v-flex xs11 text-center><span style="color:#3F053C;" class="menuhead1">Delete</span></v-flex>
                <v-flex xs1 text-right>
                  <v-icon
                  @click="dialoge = false"
                    color="#3F053C"
                    >mdi-close-box</v-icon
                  ></v-flex
                >
                <v-flex xs8 text-center py-4 >
              <v-divider></v-divider>
            </v-flex>
              </v-layout>
            <v-card-text class="pa-4 tablefont"
              >Are you sure you want to remove this item?
            </v-card-text>
            <v-card-actions class="pt-3">
              <v-spacer></v-spacer>
              <!-- <v-btn
                color="grey"
                text
                class="body-2 font-weight-bold"
                @click="dialoge = false"
                >Cancel</v-btn
              > -->
              <v-btn
                color="#3F053C"
                class="buttons1"
                dark
                @click="deleteStaff()"
                >Delete</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
   <v-dialog width="800px" v-model="editdialogue">
          <v-card width="800px" class="pa-2">
            <v-layout wrap justify-center>
                <v-flex xs11 text-center><span style="color:#3F053C;" class="menuhead1">Edit Item</span></v-flex>
                <v-flex xs1 text-right>
                  <v-icon
                  @click="editdialogue = false"
                    color="#3F053C"
                    >mdi-close-box</v-icon
                  ></v-flex
                >
                <v-flex xs8 text-center py-4 >
              <v-divider></v-divider>
            </v-flex>
              </v-layout>
           <v-layout wrap px-4>
           <v-flex xs12 sm4 py-1 pr-2>
              <v-layout wrap>
                <v-flex xs12 class="subhed2" pr-2 text-left>
                  <span>Item </span></v-flex
                >
                <v-flex xs12 pr-2>
                  <v-text-field
                    v-model="curitem.itemName"
                    outlined
                    type="text"
                    color="#b234a9"
                    placeholder="Name "
                    dense
                    hide-details
                  ></v-text-field
                ></v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm4 py-1 pr-2>
              <v-layout wrap>
                <v-flex xs12 class="subhed2" pr-2 text-left> <span>GoldRate</span></v-flex>
                <v-flex xs12 pr-2>
                  <v-text-field
                    v-model="curitem.goldRate"
                    outlined
                    type="number"
                    color="#b234a9"
                    placeholder="VA "
                    dense
                    hide-details
                  ></v-text-field
                ></v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm4 py-1 pr-2>
              <v-layout wrap>
                <v-flex xs12 class="subhed2" pr-2 text-left>
                  <span>Gross.Wt(gm)</span></v-flex
                >
                <v-flex xs12 pr-2>
                  <v-text-field
                    v-model="curitem.grossWeight"
                    outlined @input="editcal1()"
                    type="number"
                    color="#b234a9"
                    placeholder="Gross.Wt "
                    dense
                    hide-details
                  ></v-text-field
                ></v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm4 py-1 pr-2>
              <v-layout wrap>
                <v-flex xs12 class="subhed2" pr-2 text-left>
                  <span>Stone.Wt(gm)</span></v-flex
                >
                <v-flex xs12 pr-2>
                  <v-text-field
                    type="number"
                    v-model="curitem.stoneWeight"
                    outlined @input="editcal1()"
                    color="#b234a9"
                    placeholder="Stone.Wt"
                    dense
                    hide-details
                  ></v-text-field
                ></v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm4 py-1 pr-2>
              <v-layout wrap>
                <v-flex xs12 class="subhed2" pr-2 text-left>
                  <span>Net.Wt(gm)</span></v-flex
                >
                <v-flex xs12 pr-2>
                  <v-text-field
                    v-model="curitem.netWeight"
                    outlined disabled
                    type="number"
                    color="#b234a9"
                    placeholder="Net.Wt"
                    dense
                    hide-details
                  ></v-text-field
                ></v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm4 py-1 pr-2>
              <v-layout wrap>
                <v-flex xs12 class="subhed2" pr-2 text-left>
                  <span>melt(%)</span></v-flex
                >
                <v-flex xs12 pr-2>
                  <v-text-field
                    v-model="curitem.melt"
                    outlined
                    type="number"
                    color="#b234a9"
                    placeholder="Melt"
                    dense
                    hide-details
                  ></v-text-field
                ></v-flex>
              </v-layout>
            </v-flex>
           </v-layout>
            <v-card-actions class="pt-3">
              <!-- <v-spacer></v-spacer> -->
              <!-- <v-btn
                color="grey"
                text
                class="body-2 font-weight-bold"
                @click="editdialogue = false"
                >Cancel</v-btn
              > -->
              <v-layout wrap>
            <v-spacer></v-spacer>
            <v-flex xs2 mx-4>
              <v-btn
            color="#3F053C"
            class="buttons1"
            dark block
            @click="editvalidate()"
            >OK</v-btn
          >
            </v-flex>
          </v-layout>
            </v-card-actions>
          </v-card>
        </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["title2"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showsnackbar: false,
      timeout: 2000,
      msg: null,
      addcat:false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      juwellaryId: localStorage.getItem("juwellaryId"),
     
      oldGold: {
        itemName:"",
        goldRate: localStorage.getItem("goldRate"),
        quantity: "",
        grossWeight: "",
        netWeight: "",
        stoneWeight: "",
        melt: "",
        itemAmount: "",
      },
      peritem: {
        itemName:"",
        goldRate: "",
        quantity: "",
        grossWeight: "",
        netWeight: "",
        stoneWeight: "",
        melt: "",
        itemAmount: "",
      },
      oldItemz:[],
      List: [],
      saledata: [],
      ItemList:[],
      dtype:false,
      curitem:{},
      itemz2:[],
      editdialogue: false,
      curId:null,
      dialoge:false,
      total3:"",
      salesId:null,
      show: false,
    };
  },
  mounted() {
    this.salesId=this.title2._id;
    console.log("saleid2222==",this.salesId)

  },
  watch: {
    title2() {
      console.log("title2==",this.title2)
      this.salesId=this.title2._id;
      console.log("saleid==",this.salesId)
    },
    salesId(){
      this.getData();
    }
  },
  methods: {
    maxValueRule(grossWeight, stoneWeight) {
      return () => {
      if (stoneWeight >= grossWeight) {
        return `Stone weight cannot be greater than gross weight (${grossWeight})`;
      }
      return true;
    };
  
  },
  //add
    validate() {
      if (!this.oldGold.itemName) {
        this.msg = "Please enter item name";
        this.showsnackbar = true;
        return;
      } else if (!this.oldGold.goldRate) {
        this.msg = "Please enter gold rate";
        this.showsnackbar = true;
        return;
      } 
       else if (!this.oldGold.grossWeight) {
        this.msg = "Please enter gross weight";
        this.showsnackbar = true;
        return;
      } 
      else if (!this.oldGold.melt) {
        this.msg = "Please enter melt";
        this.showsnackbar = true;
        return;
      }
       else  if(this.oldGold.stoneWeight){
        var grosswt = parseFloat(this.oldGold.grossWeight);
        var stonewt = parseFloat(this.oldGold.stoneWeight);

        if (grosswt<=stonewt) {
        this.msg = "Stone weight should be less than gross weight";
        this.showsnackbar = true;
        return;
      }
      else{
        this.cal2();
      }
      }
       else {
        this.cal2();
      }
    },
    cal1() {
      if(this.oldGold.stoneWeight){
        
      // this.oldGold.netWeight = (this.oldGold.grossWeight*this.oldGold.quantity) - this.oldGold.stoneWeight;
      this.oldGold.netWeight = this.oldGold.grossWeight - this.oldGold.stoneWeight;
      
      }
      else{
        // this.oldGold.netWeight = this.oldGold.grossWeight*this.oldGold.quantity;
        this.oldGold.netWeight = this.oldGold.grossWeight;

      }
    },
    cal2() {
      console.log("1")
      if(this.oldGold.melt){
var value=this.oldGold.netWeight * this.oldGold.goldRate;
var newval =value*(this.oldGold.melt/100);
this.oldGold.itemAmount=value-newval;
console.log("totalwithmelt==",this.oldGold.itemAmount)
      }
      else{
      this.oldGold.itemAmount = this.oldGold.netWeight * this.oldGold.goldRate
console.log("totalwithoutmelt==",this.oldGold.itemAmount)

      }
      this.addValues();
    },
    //edit
    editvalidate() {
      if (!this.curitem.itemName) {
        this.msg = "Please enter item name";
        this.showsnackbar = true;
        return;
      } else if (!this.curitem.goldRate) {
        this.msg = "Please enter gold rate";
        this.showsnackbar = true;
        return;
      } 
       else if (!this.curitem.grossWeight) {
        this.msg = "Please enter gross weight";
        this.showsnackbar = true;
        return;
      }
      
       else  if(this.curitem.stoneWeight){
        var grosswt1 = parseFloat(this.curitem.grossWeight);
        var stonewt1 = parseFloat(this.curitem.stoneWeight);
        if (grosswt1<=stonewt1) {
        this.msg = "Stone weight should be less than gross weight";
        this.showsnackbar = true;
        return;
      }
        else{
          this.editcal2();
        }
     
      }
       else {
        this.editcal2();
      }
    },
    editcal1() {
      if(this.curitem.stoneWeight){
      
      // this.curitem.netWeight = (this.curitem.grossWeight*this.curitem.quantity) - this.curitem.stoneWeight;
      this.curitem.netWeight = this.curitem.grossWeight - this.curitem.stoneWeight;
     
      }
      else{
        // this.curitem.netWeight = this.curitem.grossWeight*this.curitem.quantity;
        this.curitem.netWeight = this.curitem.grossWeight;

      }
    },
    editcal2() {
      console.log("1")
      if(this.curitem.melt){
var value=this.curitem.netWeight * this.curitem.goldRate;
var newval =value*(this.curitem.melt/100);
this.curitem.itemAmount=value-newval;
console.log("totalwithmelt==",this.curitem.itemAmount)
      }
      else{
      this.curitem.itemAmount = this.curitem.netWeight * this.curitem.goldRate
console.log("totalwithoutmelt==",this.curitem.itemAmount)

      }
      this.editItem();
    },
    //
    addValues() {
      console.log("2")
      this.peritem.itemName = this.oldGold.itemName;
      this.peritem.goldRate = this.oldGold.goldRate;
      // this.peritem.quantity = this.oldGold.quantity;
      this.peritem.grossWeight = this.oldGold.grossWeight;
      this.peritem.netWeight = this.oldGold.netWeight;
      this.peritem.stoneWeight = this.oldGold.stoneWeight;
      this.peritem.melt = this.oldGold.melt;
      this.peritem.itemAmount = this.oldGold.itemAmount;
      this.addVariant();
    },
    addOldItem() {
      console.log("4")
      this.datareload=false;
      axios({
        url: "/oldgold/add",
        method: "POST",
        data: {
          isOldGold:true,
          items: this.oldItemz,
          salesId: this.salesId,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
           this.msg = response.data.msg;
            this.showsnackbar = true;
            this.oldGold.itemName = null;
      // this.oldGold.quantity = null;
      this.oldGold.grossWeight = 0;
      this.oldGold.netWeight = 0;
      this.oldGold.stoneWeight = 0;
      this.oldGold.melt = 0;
      this.oldGold.itemAmount = 0;
      this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
      this.getData();

          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    
    addVariant() {
      console.log("3")
      this.oldItemz.push(JSON.parse(JSON.stringify(this.peritem)));
      console.log("oldItemz=", this.oldItemz);
      //////
      this.oldGold.itemName = null;
      // this.oldGold.quantity = null;
      this.oldGold.grossWeight = null;
      this.oldGold.netWeight = null;
      this.oldGold.stoneWeight = null;
      this.oldGold.melt = null;
      this.oldGold.itemAmount = null;
      this.addOldItem();
      this.oldItemz=[];
    },
    getData() {
      axios({
        url: "/oldgolditem/getlist",
        method: "get",
        params: {
          salesId: this.salesId,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.List = response.data.data;
            // this.saleId = response.data.saledata._id;
            this.saledata = response.data.bill;
          } else {
            // this.msg = response.data.msg;
            // this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    deleteStaff() { 
      axios({
        url: "/oldgolditem/delete",
        method: "post",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.curId,
        },
      })
        .then((response) => {
          this.dialoge = false;
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.dialoge=false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
   
    editItem() {
      console.log("curitem=", this.curitem);
      axios({
        url: "/oldgolditem/edit",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.curitem._id,
          items: this.curitem
        },
      })
        .then((response) => {
          this.editdialogue = false;
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.editdialogue=false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
